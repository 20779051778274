import React from 'react';
import FAQ from '../sections/FAQ/FAQ';

const FAQ_PAGE = ()=>{
    return(
        <div className="page">
            <FAQ />
        </div>
        
    )
}

export default FAQ_PAGE;