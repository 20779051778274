import React from 'react';
import Terms from '../sections/terms/Terms';

const SyaratKetentuan = ()=>{
    return(
        <div className="page">
            <Terms />
        </div>
        
    )
}

export default SyaratKetentuan;