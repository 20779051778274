import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { greenGroup3, greenWhatsapp, mailIcon, normalGreen, phoneIcon, smallGreen } from '../../assets';
import KontakItem from '../../components/kontak-item/KontakItem';
import { EmailSubscribe } from '../../GraphQL/Mutations';

const Kontak = ()=>{
    const [email,setEmail] = useState('');
    const [addEmailSubscribe,{data}] = useMutation(EmailSubscribe);
    const [submit,setSubmitted] = useState(false);
    
    const onSubs = async ()=>{
        if(email && email.includes('@')){
            try{
                await addEmailSubscribe({variables:{email}});
                setSubmitted(true);
            }catch(err){
                alert('Maaf, silahkan cek koneksi anda dan coba lagi');
            }
        }else{
            alert('Isi alamat email anda')
        }
        
    }

    //RESPONSE EMAIL SUBSCRIBE
    if(submit && data){
        const status = data.EmailSubscribe.response.status;
        if(status==="0"){
            setEmail('');
            alert('Terimakasih. Kami akan memberikan informasi seputar MOSHealth melalui email Anda.');
        }else{
            alert('Maaf, terjadi kesalahan pada server. Silahkan coba kembali');
        }
        setSubmitted(false);
    }

    
    return(
        <div id="kontak" >
        <section className="kontak">
            <h1 className="kontak__title">Hubungi Kami</h1>
            <div className="kontak__card">
                <div className="kontak__content">
                    <div className="kontak__text">Customer Service MOSHealth<br/> siap membantu kamu kapanpun dimanapun</div>
                    <div className="kontak__text">Hubungi call-center MOSHealth melalui:</div>
                    <div className="kontak__items">
                        <KontakItem 
                        icon={mailIcon}
                        title="Email"
                        info="cs@moshealth.id" />
                        <KontakItem 
                        icon={phoneIcon}
                        title="Call Center"
                        info="021 508 999 81"/>
                        <KontakItem
                        icon={greenWhatsapp}
                        title="Whatsapp"
                        info="0895 3531 09000" />
                    </div>
                </div>
            </div>
            <img src={smallGreen} alt="dot" className="kontak__smallDot"/>
            <img src={greenGroup3} alt="dot" className="kontak__plus3"/>
            <img src={normalGreen} alt="" className="kontak__normalDot"/>
        </section>
        <section className="register">
            <div className="register__text">Dapatkan informasi terkini seputar MOSHealth dan juga promo menarik, dengan terhubung di mailing list kami</div>
            <div className="register__input-group">
                <input type="email" className="register__input" placeholder="Masukkan alamat email anda" value={email} 
                onChange={e=>setEmail(e.target.value)} />
                <button className="register__btn" onClick={onSubs}>Daftar</button>
            </div>
        </section>
        </div>
    )
}

export default Kontak;