import React from 'react';
import { 
    bigPhone, 
    mediumYellow, 
    normalYellow,
    smallYellow2, 
    smallYellow,
    smallerYellow,
    yellowPlus,
    yellowGroup2,
    yellowGroup3,
    playstore} from '../../assets';

const Beranda = ()=>{
    return (
        <section className="beranda" id="beranda">
            <div className="beranda__line"></div>
            <div className="beranda__contents">
                <div className="beranda__content">
                    <h1 className="beranda__title">Trusted Solution <br></br> for Your Health</h1>
                    <h1 className="beranda__tag"><span>#SolusiSehatMoshealthaja </span><span>#Yuk100%Sehat</span> </h1>
                    <a href="https://play.google.com/store/apps/details?id=com.moshealth.digitalbusiness.moshealth&hl=in" className="beranda__btn" rel="noopener noreferrer" target="_blank"  >Download</a>
                </div>
                <div className="beranda__image">
                    <img src={bigPhone} alt="mos health phone" className="beranda__phone"/>
                    <img src={playstore} alt="playstore" className="beranda__playstore"/>
                </div>
            </div>
            {/* CIRCLE */}
            <img src={mediumYellow} alt="yellow circle" className="beranda__bigDot"/>
            <img src={normalYellow} alt="yellow circle" className="beranda__mediumDot"/>
            <img src={smallYellow2} alt="yellow circle" className="beranda__smallDot"/>
            <img src={smallYellow} alt="yellow circle" className="beranda__smallDot2"/>
            <img src={smallerYellow} alt="yellow circle"  className="beranda__smallerDot"/>
            <img src={smallerYellow} alt="yellow circle" className="beranda__smallerDot2"/>

            {/* PLUS */}
            <img src={yellowPlus} alt="yellow Plus" className="beranda__plus1"/>
            <img src={yellowGroup2} alt="yellow plus" className="beranda__plus2"/>
            <img src={yellowGroup3} alt="yellow plus" className="beranda__plus3"/>
        </section>
    )
}

export default Beranda;