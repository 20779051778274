import React from 'react';


const MottoItem = ({image,title,text,minusLeft,smallIcon})=>{
    let imgClass = "motto-item__img";
    if(minusLeft){
       imgClass += " motto-item__cepat"
    }else if(smallIcon){
        imgClass += " motto-item__mudah"
    }
    return(
        <div className="motto-item">
            <img 
            src={image} alt="touch" className={imgClass} />
            <div className="motto-item__title">{title}</div>
            <div className="motto-item__text">{text}</div>
        </div>
    )
}

MottoItem.defaultProps={
    minusLeft:false,
    smallIcon:false
}

export default MottoItem;