import React, { useEffect, useState } from 'react';
import { NavLink ,useHistory,useLocation} from 'react-router-dom';
import { Link as ScrollLink} from 'react-scroll';
import {  mediumYellow3, MOSHealthLogo, normalYellow, smallYellow, whiteFacebook, whiteInstagram, yellowGroup2, yellowGroup3, yellowPlus } from '../../assets';


const MainPageNavigation = ()=>{

    return(
        <>
            <ScrollLink 
            className="nav__item" 
            activeClass="nav--active" 
            to="beranda" 
            spy={true}
            offset={-130}
            smooth={true}
            duration={700}>Beranda</ScrollLink>

            <ScrollLink 
            className="nav__item" 
            activeClass="nav--active" 
            to="tentang" 
            spy={true}
            hashSpy={true}
            smooth={true}
            offset={-130}
            duration={700}>Tentang</ScrollLink>

            <ScrollLink 
            className="nav__item"
            activeClass="nav--active"
            to="bagaimana"
            spy={true}
            hashSpy={true}
            smooth={true}
            offset={-130}
            duration={700}>Bagaimana</ScrollLink>

            <ScrollLink 
            className="nav__item"
            activeClass="nav--active"
            to="bisnis"
            spy={true}
            hashSpy={true}
            smooth={true}
            offset={-127}
            duration={700}>Bisnis</ScrollLink>

            <ScrollLink 
            className="nav__item"
            activeClass="nav--active"
            to="kontak"
            spy={true}
            hashSpy={true}
            smooth={true}
            offset={-127}
            duration={700}>Kontak</ScrollLink>
        </>
    )
} 

const SubPageNavigation = ({location})=>{
    const renderBisnisNav = 
        location==='/bisnis-register'?
        <NavLink className="nav__item" activeClassName="nav--active" to="/bisnis-register" exact>Bisnis</NavLink>:
        <NavLink className="nav__item" activeClassName="nav--active" to="/bisnis" exact>Bisnis</NavLink>
    return(
        <>
            <NavLink className="nav__item" activeClassName="nav--active" to="/" exact>Beranda</NavLink>
            <NavLink className="nav__item" activeClassName="nav--active" to="/tentang" exact>Tentang</NavLink>
            <NavLink className="nav__item" activeClassName="nav--active" to="/bagaimana" exact>Bagaimana</NavLink>
            {renderBisnisNav}
            <NavLink className="nav__item" activeClassName="nav--active" to="/kontak" exact>Kontak</NavLink>
        </>
    )
}



const scrollToSection = (yAxis)=>{
    setTimeout(()=>{
        window.scrollTo({
            behavior:"smooth",
            top:yAxis
        })
    })
}

const Navigation = ()=>{
    //state management
    const [onMainPage,setOnMainPage] = useState(true);
    const [onActiveNav,setOnActiveNav] = useState({
        responsiveBtnClass:'nav--button',
        responsiveMenuClass:'nav--menus'
    });

    //variables
    let location= useLocation().pathname;
    const history = useHistory();
    const fullHeight = window.innerHeight;
    const currentWidth = window.innerWidth;


    //watch change
    useEffect(()=>{
        switch(location.toLowerCase()){
            case '/':
                setOnMainPage(true);
                break;
            case '/tentang':
                setOnMainPage(true);
                history.push("/");
                scrollToSection(.9*fullHeight);
                break;
            case '/bagaimana':
                setOnMainPage(true);
                history.push("/");
                if(currentWidth <= 600){
                    scrollToSection(fullHeight+550+1300+1050);
                }else{
                    scrollToSection(3.35*fullHeight);
                }
                break;
            case '/bisnis':
                setOnMainPage(true);
                history.push("/");
                if(currentWidth <= 600){
                    scrollToSection(fullHeight+550+1300+1050+500);
                }
                else if(currentWidth <= 800){
                    scrollToSection(fullHeight+300+1300+1050+500);
                }
                else{
                    scrollToSection(4.23*fullHeight)
                }
                
                break;
            case '/kontak':
                setOnMainPage(true);
                history.push("/");
                if(currentWidth <= 600){
                    scrollToSection(fullHeight+550+1300+1050+500+500);
                }
                else if(currentWidth <= 800){
                    scrollToSection(fullHeight+300+1300+1050+500+500);
                }
                else{
                    scrollToSection(5.1*fullHeight)
                }
                
                break;
            case '/faq':
                scrollToSection(0);
                setOnMainPage(false);
                break;
            case '/bisnis-register':
                scrollToSection(0);
                setOnMainPage(false);
                break;
            case '/terms':
                scrollToSection(0);
                setOnMainPage(false);
                break;
            default:
        }

        
    },[location,history,fullHeight,currentWidth]);
    
    //control responsive nav
    const controlResponsiveNav = ()=>{
       if(onActiveNav.responsiveBtnClass === 'nav--button'){
            setOnActiveNav({
                responsiveBtnClass:'nav--button nav--button--active',
                responsiveMenuClass:'nav--menus nav--menus--active'
            })
       }else{
            setOnActiveNav({
                responsiveBtnClass:'nav--button',
                responsiveMenuClass:'nav--menus'
            })
       }
       
    }
    // RESPONSIVE NAV
    const MainPageNavigationResponsive = ()=>{
        return(
            <>
                <ScrollLink 
                className="nav__item" 
                activeClass="nav--active" 
                to="beranda" 
                spy={true}
                offset={-130}
                smooth={true}
                duration={700}
                onClick={()=>controlResponsiveNav()}>Beranda</ScrollLink>

                <ScrollLink 
                className="nav__item" 
                activeClass="nav--active" 
                to="tentang" 
                spy={true}
                hashSpy={true}
                smooth={true}
                offset={-130}
                duration={700}
                onClick={()=>controlResponsiveNav()}>Tentang</ScrollLink>

                <ScrollLink 
                className="nav__item"
                activeClass="nav--active"
                to="bagaimana"
                spy={true}
                hashSpy={true}
                smooth={true}
                offset={-130}
                duration={700}
                onClick={()=>controlResponsiveNav()}>Bagaimana</ScrollLink>

                <ScrollLink 
                className="nav__item"
                activeClass="nav--active"
                to="bisnis"
                spy={true}
                hashSpy={true}
                smooth={true}
                offset={-127}
                duration={700}
                onClick={()=>controlResponsiveNav()}>Bisnis</ScrollLink>

                <ScrollLink 
                className="nav__item"
                activeClass="nav--active"
                to="kontak"
                spy={true}
                hashSpy={true}
                smooth={true}
                offset={-127}
                duration={700}
                onClick={()=>controlResponsiveNav()}>Kontak</ScrollLink>
            </>
        )
    } 

    const SubPageNavigationResponsive = ({location})=>{
        const renderBisnisNav = 
            location==='/bisnis-register'?
            <NavLink className="nav__item" activeClassName="nav--active" to="/bisnis-register" exact onClick={()=>controlResponsiveNav()}>Bisnis</NavLink>:
            <NavLink className="nav__item" activeClassName="nav--active" to="/bisnis" exact onClick={()=>controlResponsiveNav()}>Bisnis</NavLink>
        return(
            <>
                <NavLink className="nav__item" activeClassName="nav--active" to="/" exact onClick={()=>controlResponsiveNav()}>Beranda</NavLink>
                <NavLink className="nav__item" activeClassName="nav--active" to="/tentang" exact onClick={()=>controlResponsiveNav()}>Tentang</NavLink>
                <NavLink className="nav__item" activeClassName="nav--active" to="/bagaimana" exact onClick={()=>controlResponsiveNav()}>Bagaimana</NavLink>
                {renderBisnisNav}
                <NavLink className="nav__item" activeClassName="nav--active" to="/kontak" exact onClick={()=>controlResponsiveNav()}>Kontak</NavLink>
            </>
        )
    }
    
    return(
        <>
        <div className="nav">
            <NavLink to="/" >
                <img src={MOSHealthLogo} alt="MOSHealth" className="nav__logo"/>
            </NavLink>
            {/* BIG NAVIGATION */}
            <div className="nav__items">
                {onMainPage ? <MainPageNavigation /> : <SubPageNavigation location={location} />}

                <NavLink className="nav__item" activeClassName="nav--active" to="/FAQ" exact>FAQ</NavLink>
                <div className="nav__divider"></div>
                <a href="https://play.google.com/store/apps/details?id=com.moshealth.digitalbusiness.moshealth&hl=in" rel="noopener noreferrer" target="_blank"
                 className="nav__item nav__bold">Download</a>
                <div className="nav__media"> 
                    <a href="https://www.instagram.com/moshealth/" rel="noopener noreferrer" target="_blank">
                        <img src={whiteInstagram} alt="instagram" className="nav__media-item-ig"/>
                    </a>
                   <a href="https://www.facebook.com/moshealthid/" rel="noopener noreferrer" target="_blank">
                    <img src={whiteFacebook} alt="facebook" className="nav__media-item-fb"/>
                   </a>
                    
                </div>
            </div>
            {/* RESPONSIVE TOGGLE */}
            <div className="nav--items">
                <div className="nav__media"> 
                    <a href="https://www.instagram.com/moshealth/" rel="noopener noreferrer" target="_blank">
                        <img src={whiteInstagram} alt="instagram" className="nav__media-item-ig" onClick={()=>controlResponsiveNav()}/>
                    </a>
                    <a href="https://www.facebook.com/moshealthid/" rel="noopener noreferrer" target="_blank">
                        <img src={whiteFacebook} alt="facebook" 
                        className="nav__media-item-fb" onClick={()=>controlResponsiveNav()}/>
                    </a>
                    
                </div>
                <div className="nav--container" 
                onClick={()=>controlResponsiveNav()}>
                    <div className={onActiveNav.responsiveBtnClass}></div>
                </div>
            </div>
            
        </div>
        {/* RESPONSIVE MENUS */}
        <div className={onActiveNav.responsiveMenuClass}>
            {onMainPage ? 
            <MainPageNavigationResponsive /> : 
            <SubPageNavigationResponsive location={location} />}

            <NavLink className="nav__item" activeClassName="nav--active" to="/FAQ" exact onClick={()=>controlResponsiveNav()}>FAQ</NavLink>
            <a href="https://play.google.com/store/apps/details?id=com.moshealth.digitalbusiness.moshealth&hl=in" rel="noopener noreferrer" target="_blank"
             className="nav__item nav__bold" onClick={()=>controlResponsiveNav()}>Download</a>

            <div className="nav--extra">
                <img src={yellowPlus} alt="" className="nav--plus1"/>
                <img src={yellowGroup2} alt="" className="nav--plus2"/>
                <img src={yellowGroup3} alt="" className="nav--plus3"/>

                <img src={normalYellow} alt="" className="nav--dot1"/>
                <img src={smallYellow} alt="" className="nav--dot2"/>
                <img src={mediumYellow3} alt="" className="nav--dot3"/>
                <img src={smallYellow} alt="" className="nav--dot4"/>
            </div>
            
        </div>
        </>
    )
}

export default Navigation;