import React from 'react';
import { NavLink } from 'react-router-dom';
import { greenFacebook, greenInstagram, MOSHealthLogoSmall } from '../../assets';

const Footer = () =>{
    return(
        <footer className="footer">
            <div className="footer__top">
                <img className="footer__logo" src={MOSHealthLogoSmall} alt="moshealth"/>
                <div className="footer__site">
                    <div className="footer__title">Site Map</div>
                    <NavLink to="/FAQ" className="footer__item">FAQ</NavLink>
                    <NavLink to="/terms" className="footer__item">Syarat & Ketentuan</NavLink>
                    <NavLink to="/" className="footer__item">Promo</NavLink>
                    <NavLink to="/" className="footer__item">Media</NavLink>
                </div>
                <div className="footer__hubungi">
                    <div className="footer__title">Hubungi Kami</div>
                    <div className="footer__text">021-508 999 81</div>
                    <div className="footer__text">WA : 0895 3531 09000</div>
                    <div className="footer__text">business@moshealth.id</div>
                    <div className="footer__text">MOSHealth Head Office</div>
                    <div className="footer__text">Jalan Pulo Lentut no 12</div>
                    <div className="footer__text">Kawasan Industri Pulogadung</div>
                    <div className="footer__text">Jakarta Timur - 13920</div>
                </div>
            </div>
            
            <div className="footer__foot">
                <div className="footer__more">@MOSHealth, 2020. All Rights Reserved</div>
                <div className="footer__more">Ikuti sosial media kami di 
                <a href="https://www.instagram.com/moshealth/" rel="noopener noreferrer" target="_blank">
                    <img src={greenInstagram} alt="instagram" className="footer__icon"/>
                </a>

                <a href="https://www.facebook.com/moshealthid/" rel="noopener noreferrer" target="_blank">
                    <img src={greenFacebook} alt="facebook" 
                    className="footer__icon"/>
                </a>
                    
                    
                </div>
            </div>
        </footer>
    )
}

export default Footer;