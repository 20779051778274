import React from 'react';

const FiturItem = ({image,title,text,addClass})=>{
    let imgClass = "fitur-item__image ";
    if(addClass){
        imgClass+= addClass
    }
    return(
        <div className="fitur-item">
            <img src={image} alt="fitur" className={imgClass}/>
            <div className="fitur-item__info">
                <p className="fitur-item__title">{title}</p>
                <p className="fitur-item__text">{text}</p>
            </div>
        </div>
    )
}

export default FiturItem;