import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './sass/main.scss';
import 'font-awesome/css/font-awesome.min.css';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';


//https://api.moshealth.co.id:5070/v1
const client = new ApolloClient({
  uri: 'https://api.moshealth.co.id:5070/v1',
  cache: new InMemoryCache(),
  defaultOptions:{
    mutate:{errorPolicy:'ignore'}
  }
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);

