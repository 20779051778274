import React, { useState } from 'react';
import { greenGroup3, mediumYellow, normalGreen, normalYellow, smallGreen, smallYellow2, yellowGroup2, yellowPlus } from '../../assets';
import Footer from '../../components/footer/Footer';


const FAQItem = ({title,contents})=>{
    const [active,setActive] = useState(false);
    let FAQItemClass = 'FAQ-item';
    if(active){
        FAQItemClass += ' FAQ-item--active'
    }else{
        FAQItemClass = 'FAQ-item';
    }

    //render content
    const renderContent = contents.map((content,index)=>{
        //render jawaban
        const jawaban = content.jawaban.split('<br>').map((jwb,idx)=>{
            return(
                <div key={idx}>
                    {jwb} <br/>
                </div>
            )
        })
        
        return(
            <div className="FAQ-item__content" key={index} >
                <div className="FAQ-item__pertanyaan">{content.pertanyaan}</div>
                <div className="FAQ-item__jawaban">{jawaban}</div>
            </div>
        )
    })

    return(
        <div className={FAQItemClass} onClick={()=>setActive(!active)}>
            <div className="FAQ-item__menu">
                <div className="FAQ-item__title">{title}</div>
                <i className="fa fa-angle-right FAQ-item__icon" aria-hidden="true"></i>
            </div>
            {/* CONTENT HERE */}
            <div className="FAQ-item__contents">
                {renderContent}
            </div>
            
        </div>
    )
}

// ISI FAQ
const seputarMOSHealth = [
    {
        pertanyaan:"Apakah MOSHealth memiliki toko retail?",
        jawaban:"Tidak. MOSHealth adalah sebuah aplikasi yang membantu rekanan apotek untuk menjual produk kesehatan secara online."
    },
    {
        pertanyaan:"Apa yang bisa saya lakukan di Aplikasi MOSHealth?",
        jawaban:"Anda bisa melakukan pemesanan dan pembelian produk-produk yang tersedia di MOSHealth.<br>Dari produk resep maupun produk non resep."
    },
    {
        pertanyaan:"Saya membutuhkan pertolongan. Kemana saya bisa menghubungi team MOSHealth?",
        jawaban:`Jika Anda mengalami kendala dan butuh pertolongan, silahkan menghubungi CS MOSHealth ke nomor (021)
        1500-462 atau email ke cs@MOSHealth.com.`
    },
    {
        pertanyaan:"Apakah saya harus menjadi member untuk dapat berbelanja di MOSHealth?",
        jawaban: `Untuk berbelanja di MOSHealth, Anda diwajibkan untuk menjadi member MOSHealth terlebih dahulu.`
    }
];

const pendaftaran = [
    {
        pertanyaan:`Bagaimana caranya untuk menjadi member?`,
        jawaban:`Untuk menjadi member MOSHealth terdapat 3 cara, sebagai berikut: <br><br>
        Mendaftarkan email Anda secara manual ke dalam website MOSHealth.<br>
        Dengan memilih menu Register yang terdapat di kanan atas halaman website dan lengkapi data diri Anda.<br>
        Mendaftar dengan menggunakan Facebook.<br>
        Mendaftar dengan menggunakan Google.<br>`
    },
    {
        pertanyaan:`Apakah ada biaya untuk bergabung di MOSHealth?`,
        jawaban:`Untuk bergabung di MOSHealth, Anda tidak dikenakan biaya apapun.`
    },
    {
        pertanyaan:`Bagaimana cara saya mengelola akun saya?`,
        jawaban:`Jika Anda sudah mendaftarkan diri, Anda dapat Login setiap saat menggunakan alamat email dan password yang
        sudah terdaftar di MOSHealth.`
    },
    {
        pertanyaan:`Mengapa saya tidak bisa masuk / Log in ke dalam MOSHealth?`,
        jawaban:`Mungkin Anda salah memasukkan email atau password Anda. Mohon cek kembali email dan password Anda
        sebelum melakukan Log in.`
    },
    {
        pertanyaan:`Bagaimana jika saya lupa password/kata sandi saya?`,
        jawaban:`<br>
        Jika Anda lupa password/kata sandi Anda, Anda tidak perlu khawatir, Anda bisa mengikuti panduan berikut ini:<br><br>
        Klik LOGIN pada sisi kanan atas.<br>
        Lalu klik Lupa Password ?.<br>
        Masukkan alamat email dan klik KIRIM EMAIL.<br>
        MOSHealth akan mengirimkan email perubahan password/kata sandi.<br>
        Buka email, lalu klik link yang tersedia.<br>
        Isi data dengan benar, lalu klik RESET.<br>`
    }
];

const pengiriman = [
    {
        pertanyaan:`Mana sajakah area pengiriman MOSHealth?`,
        jawaban:`Saat ini, MOSHealth sudah bisa melayani pengiriman ke semua kota di Indonesia.`
    },
    {
        pertanyaan:`Berapa lama pesanan saya akan sampai di rumah?`,
        jawaban:`Estimasi waktu pengiriman bergantung pada jenis layanan pengiriman yang Anda pilih. Berikut estimasi pengiriman
        yang Anda pilih :<br><br>
        RPX Nextday: Pesanan akan sampai dalam waktu 1-3 hari kerja.<br>
        RPX Regular: Pesanan akan sampai dalam waktu 2-5 hari kerja.<br>
        J&T Express: Pesanan akan sampai dalam waktu 2-5 hari kerja.<br>

        Grab Express: Pesanan akan sampai dalam waktu maksimal 4 jam, batas pengiriman 30 KM. Dengan batas akhir
        pemesanan pukul 15:00 WIB, pemesanan melebihi waktu yang ditentukan akan dikirim pada hari berikutnya.<br>

        GoSend Instant: Pesanan akan sampai dalam waktu maksimal 4 jam, batas pengiriman 30 KM. Dengan batas akhir
        pemesanan pukul 17:00 WIB, pemesanan melebihi waktu yang ditentukan akan dikirim pada hari berikutnya.<br>

        GoSend Sameday: Pesanan akan sampai dalam waktu maksimal 8 jam, batas pengiriman 30 KM. Dengan batas
        akhir pemesanan pukul 14:00 WIB, pemesanan melebihi waktu yang ditentukan akan dikirim pada hari berikutnya.`
    },
    {
        pertanyaan:`Apakah rider yang mengantarkan pesanan saya akan menghubungi saya sebelum mengantarkan barang
        pesanan?`,
        jawaban:`Tidak. MOSHealth akan mengirimkan notifikasi berupa email sebagai konfirmasi produk akan segera dikirimkan.<br>
        Jika Anda ingin mengetahui status pengiriman, Anda bisa mengecek melalui web atau mobile MOSHealth di dalam
        menu transaksi.`
    },
    {
        pertanyaan:`Bagaimana jika saat pesanan diantarkan, saya tidak ada di rumah?`,
        jawaban:`Jika Anda tidak ada dirumah/di tempat tujuan. Kurir akan menghubungi Anda via telepon/sms untuk meminta
        informasi dari Anda lebih lanjut.`
    },
    {
        pertanyaan:`Apakah saya bisa melakukan pembatalan pesanan dan apa ketentuannya?`,
        jawaban:`Jika ingin membatalkan pesanan untuk transaksi yang belum dibayar, maka tidak membutuhkan persetujuan dari
        MOSHealth. Anda dapat membiarkan pesanan yang Anda pesan. <br>
        Jika ingin membatalkan pesanan untuk transaksi yang sudah dibayar tetapi belum diproses maupun sudah
        terproses, silahkan menghubungi Customer Service MOSHealth di 1500-462, karena dibutuhkan persetujuan dari
        pihak MOSHealth.`
    },
    {
        pertanyaan:`Apakah saya bisa melacak pesanan?`,
        jawaban:`Anda dapat melakukan pelacakan pesanan Anda, dengan cara:<br>
        Klik icon akun pada sisi kanan atas.<br>
        Klik Pesanan.`
    },
    {
        pertanyaan:`Apakah MOSHealth akan menyimpan pesanan obat saya?`,
        jawaban:`Semua pesanan Anda akan tersimpan pada menu Pesanan.`
    },
    {
        pertanyaan:`Apakah saya bisa melakukan pemesanan untuk orang lain diluar alamat pengiriman saya sebelumnya?`,
        jawaban:`Tentu saja Anda bisa memesankan produk untuk orang lain, hanya dengan menambahkan alamat pengiriman yang
        dituju.`
    }
];

const resep=[
    {
        pertanyaan:`Apakah MOSHealth bisa melayani pembelian obat resep?`,
        jawaban:`Tentu saja MOSHealth dapat melayani pembelian Resep hanya dengan mengunggah resep yang telah diberikan
        oleh dokter.`
    },
    {
        pertanyaan:`Bagaimana caranya untuk menebus resep di MOSHealth?`,
        jawaban:`Saat ini MOSHealth memiliki 2 cara untuk menebus resep, sebagai berikut:<br><br>
        Anda dapat memilih obat resep terlebih dahulu baru Anda mengunggah resep Anda, caranya:
        1. Masukkan nama produk resep yang Anda butuhkan pada pencarian, lalu klik enter. <br>
        2. Klik QUICKVIEW atau TOKO LAINNYA.<br>
        3. Tentukan kuantitas obat yang Anda butuhkan, lalu klik BELI, secara otomatis produk akan masuk ke Resep saya.<br>
        4. Klik UNGGAH RESEP, masukkan foto resep yang telah Anda Scan/Foto dan berikan nomor telepon/catatan Anda
        ke Apotek. Lalu klik UNGGAH.<br>
        5. Klik Kirim Resep. Tunggu hingga Apotek merespon resep Anda.<br>
        6. Jika Apotek sudah merespon resep Anda. Klik Tambahkan ke Keranjang, maka produk akan masuk ke dalam
        keranjang.<br>
        7. Lalu lakukan proses pemesanan seperti biasa, hingga ke pembayaran.<br><br>
        
        Anda dapat langsung melakukan unggah Resep, caranya:<br>
        1. Klik icon Resep saya yang berada di sisi kanan atas.<br>
        2. Klik tab RESEP UNGGAHAN.<br>
        3. Klik Unggah Resep. Lalu, masukkan foto resep yang telah Anda Scan/Foto dan berikan nomor telepon/catatan
        Anda ke Apotek,.<br>
        4. Pilih apotek yang akan Anda kirimkan resep. Lalu klik UNGGAH.<br>
        5. Klik Kirim Resep. Tunggu hingga Apotek merespon resep Anda.<br>
        6. Jika Apotek sudah merespon resep Anda. Klik Tambahkan ke Keranjang, maka produk akan masuk ke dalam
        keranjang.<br>
        7. Lalu lakukan proses pemesanan seperti biasa, hingga ke pembayaran.`
    },
    {
        pertanyaan:`Apakah saya dapat menebus resep dari apotik tertentu, misalnya dari apotik langganan saya?`,
        jawaban:`Bisa, dengan cara memilih langsung obat resep yang berada di halaman Apotek langganan Anda atau
        mengunggah langsung resep dengan memilih Apotek langganan Anda yang berada di halaman Resep Saya pada
        Tab RESEP UNGGAHAN.`
    },
    {
        pertanyaan:`Apakah ada syarat untuk menebus obat resep di MOSHealth?`,
        jawaban:`Syarat dan ketentuan untuk menebus obat resep adalah Anda harus memiliki RESEP FISIK ASLI. Pada saat kurir
        mengantar pesanan resep Anda, Anda diwajibkan untuk menyerahkan resep fisik Anda pada kurir, jika tidak kurir
        tidak akan menyerahkan pesanan kepada Anda. Untuk obat resep sendiri, MOSHealth tidak melayani pembelian
        obat–obatan resep golongan psikotropika, prekursor, dan obat-obat tertentu yang rawan diversi, serta tidak
        menjual narkotika.`
    },
    {
        pertanyaan:`Apakah ada biaya tambahan untuk menebus resep di MOSHealth?`,
        jawaban:`Tidak ada biaya tambahan.`
    },
    {
        pertanyaan:`Berapa lama saya akan mendapatkan konfirmasi dari merchant atas resep yang telah saya upload?`,
        jawaban:`Anda akan mendapatkan konfirmasi dari Apotek paling lambat 60 menit setelah Anda kirim resep. Jika melebihi
        waktu tersebut, silahkan menanyakan ke Customer Service kami jika perlu.`
    },
    {
        pertanyaan:`Konfirmasi dari merchant atas resep yang telah saya upload, akan saya terima dalam bentuk apa?`,
        jawaban:`Anda akan menerima konfirmasi dalam bentuk email dan notifikasi.`
    },
    {
        pertanyaan:`Apakah obat resep bisa diorder tanpa resep?`,
        jawaban:`Untuk beberapa obat yang sifatnya esensial atau obat wajib apotik, Apoteker/Apotek bisa melayani tanpa resep,
        namun tergantung dari masing-masing kebijakan Apoteker/Apotek, dengan mempertimbangkan prinsip kewajaran
        dan resiko.`
    },
    {
        pertanyaan:`Apa saya bisa mengorder obat / vitamin lain, selain obat dari resep yang saya tebus?`,
        jawaban:`Bisa. Anda bisa mencari obat/vitamin lain lalu memasukkan ke keranjang. Obat resep bisa dicampur dengan obat
        yang lainnya tetapi dengan syarat beli di Apotek yang sama.`
    }
];

const pembayaran=[
    {
        pertanyaan:`Metode pembayaran apa saja yang tersedia di MOSHealth?`,
        jawaban:`Saat ini MOSHealth menyediakan pelayanan pembayaran, seperti berikut:<br>
        Bank Transfer (BCA)<br>
        Credit Card<br>
        Virtual Account Permata<br>
        Cash on Delivery (COD)`
    },
    {
        pertanyaan:`Bagaimana cara saya membayar dengan credit card?`,
        jawaban:`Setelah Anda memilih cara pembayaran dengan credit card, Anda akan diminta untuk memasukkan nomor kartu
        kredit Anda dan data-data lain yang dibutuhkan. Kemudian, kami akan memproses pembayaran Anda.`
    },
    {
        pertanyaan:`Apa itu Cash On Delivery?`,
        jawaban:`Cash on Delivery/COD adalah pembayaran secara langsung (cash) oleh pembeli yang akan dibayarkan melalui kurir
        kami saat mengantarkan pesanan Anda.`
    },
    {
        pertanyaan:`Apa itu Virtual Account Permata?`,
        jawaban:`Pembayaran Virtual Account Permata adalah proses pembayaran dengan cara transfer uang ke nomor rekening
        MOSHealth dengan menggunakan Bank Permata. No rekening akan Anda dapatkan via email dari kami, setelah
        Anda melakukan pemesanan.`
    },
    {
        pertanyaan:`Apakah pesanan saya sudah diproses?`,
        jawaban:`Anda bisa mengecek orderan anda pada icon Akun saya yang berada sisi kanan atas, lalu klik Pesanan.`
    }
];

const refund = [
    {
        pertanyaan:`Apabila saya ingin melakukan pengembalian barang, apa yang harus saya lakukan?`,
        jawaban:`Jika Anda ingin mengembalikan barang, silahkan Anda menghubungi Customer Service kami ke 1500-462 untuk
        dibuatkan nomor pengembalian barang. Setelah itu, kami akan memproses pengembalian barang tersebut.<br>
        Pengembalian barang hanya dapat dilakukan pada saat serah terima dengan kurir dilakukan, mohon cek pesanan
        Anda sebelum kurir kami meninggalkan Anda`
    },
    {
        pertanyaan:`Berapa lama pengembalian Dana saya akan dikembalikan?`,
        jawaban:`Pengembalian dana Anda akan dikembalikan maksimal 7 hari kerja sejak pengajuan pengembalian dana.`
    }
];

const komplain=[
    {
        pertanyaan:`Apabila saya ingin melakukan komplain pesanan, apa yang harus saya lakukan?`,
        jawaban:`Komplain pesanan hanya bisa dilayani 1x24 jam setelah barang diterima oleh customer dengan menghubungi
        customer service kami di 1500-462 atau melalui email cs@MOSHealth.com`
    }
]

const FAQ = ()=>{
    return (
        <>
        <div className="circle"></div>
        <section className="FAQ">
            <div className="FAQ__container">
                <h1 className="FAQ__title">Frequently Asked Question</h1>
                <div className="FAQ__items">
                    <FAQItem title="Seputar MOSHealth" contents={seputarMOSHealth} />
                    <FAQItem title="Pendaftaran" contents={pendaftaran} />
                    <FAQItem title="Pengiriman" contents={pengiriman} />
                    <FAQItem title="Resep" contents={resep} />
                    <FAQItem title="Pembayaran" contents={pembayaran} />
                    <FAQItem title="Return & Refund" contents={refund} />
                    <FAQItem title="Komplain Pesanan" contents={komplain} />
                    <img src={smallYellow2} alt="" className="FAQ__smallerYellow2"/>
                </div>
            </div>
            {/* YELLOW CIRCLE */}
            <img src={mediumYellow} alt="" className="FAQ__bigYellow"/>
            <img src={normalYellow} alt="" className="FAQ__mediumYellow"/>
            <img src={smallYellow2} alt="" className="FAQ__smallYellow"/>
            <img src={smallYellow2} alt="" className="FAQ__smallerYellow"/>
            
            {/* GREEN CIRCLE */}
            <img src={smallGreen} alt="" className="FAQ__smallGreen"/>
            <img src={normalGreen} alt="" className="FAQ__normalGreen"/>

            {/* YELLOW PLUS */}
            <img src={yellowPlus} alt="" className="FAQ__plus1"/>
            <img src={yellowGroup2} alt="" className="FAQ__plus2"/>

            {/* GREEN PLUS */}
            <img src={greenGroup3} alt="" className="FAQ__plus3"/>
        </section>
        <Footer/>
        </>
    )
}

export default FAQ;