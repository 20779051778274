import React from 'react';
import { NavLink } from 'react-router-dom';
import { apotek2, smallGreen, smallYellow, yellowGroup2, yellowGroup3 } from '../../assets';

const Bisnis = ()=>{
    return(
        <section className="bisnis" id="bisnis" >
            
            <h1 className="bisnis__title">Bergabung Bersama <br className="bisnis__break" /> Moshealth</h1>
            <div className="bisnis__container">
                <div className="bisnis__content">
                    <strong className="bisnis__text">
                        Mari bergabung menjadi <br/> Apotek Partner MOSHealth <br/>
                        Layani pelanggan secara online <br/>
                        dengan mudah dan aman
                    </strong>
                    <NavLink 
                    className="bisnis__btn"
                    to="/bisnis-register" >Daftar Sekarang</NavLink>
                    
                </div> 
                <div className="bisnis__image">
                    <img src={apotek2} alt="apotek" className="bisnis__apotek"/>
                    <img src={smallGreen} alt="green dot" className="bisnis__greenDot"/>
                </div>
            </div>
            {/* images */}
            <img src={smallYellow} alt="dot" className="bisnis__yellowDot"/>
            <img src={yellowGroup2} alt="plus" className="bisnis__plus2"/>
            <img src={yellowGroup3} alt="plus" className="bisnis__plus3"/>
            
        </section>
    )
}

export default Bisnis;