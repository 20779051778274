import { useQuery } from '@apollo/client';
import React from 'react';
import Footer from '../components/footer/Footer';
import Bagaimana from '../sections/bagaimana/Bagaimana';
import Beranda from '../sections/beranda/Beranda';
import Bisnis from '../sections/bisnis/Bisnis';
import Kontak from '../sections/kontak/Kontak';
import Tentang from '../sections/tentang/Tentang';

const Home = (props)=>{
    return (
        <div className="page">
            <Beranda />
            <Tentang tentangRef={props.tentangRef} />
            <Bagaimana />
            <Bisnis />
            <Kontak />
            <Footer />
        </div>
    )
}

export default Home;