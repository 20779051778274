import React, { useState } from 'react';
import Footer from '../components/footer/Footer';
import BisnisForm from '../sections/bisnis-register/bisnis-form/BisnisForm';
import BisnisResponse from '../sections/bisnis-register/bisnis-response/BisnisResponse';

const BisnisRegister = ()=>{
    const [submitted,setSubmitted] = useState(false);
    const renderContent = submitted? <BisnisResponse state={submitted} /> :<BisnisForm setSubmitted={setSubmitted} />
    return(
       <div className="page">
       {renderContent}
       <Footer/>
       </div>
    )
}

export default BisnisRegister;