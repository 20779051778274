import React from 'react';

const KontakItem = ({icon,title,info})=>{
    return(
        <div className="kontak-item">
            <img src={icon} alt="email" className="kontak-item__icon"/>
            <div className="kontak-item__title">{title}</div>
            <div className="kontak-item__info">{info}</div>
            <i className="fa fa-chevron-right kontak-item__arrow" aria-hidden="true"></i>
        </div>
    )
}

export default KontakItem;