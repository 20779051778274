import React from 'react';

const Spinner = ()=>{
    return(
        <div className="loading-container">
            <section className="loading">
                <span>Loading...</span>
            </section>
        </div>
    )
}

export default Spinner;