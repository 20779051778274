import React from 'react';
import { greenGroup3, timerIcon, touchIcon, trustIcon } from '../../../assets';
import MottoItem from '../../../components/motto-item/MottoItem';

const Motto = ()=>{
    return(
        <section className="motto">
            <div className="motto__header">
                <h1 className="motto__title">Hi Healthy Buddy!</h1>
                <p className="motto__text">Kami dengan senang hati membantumu untuk menemukan produk <br/> 
                obat-obatan dan vitamin yang berkualitas. Dengan pengiriman yang dilakukan oleh Apotek <br/> yang terdekat dengan lokasimu.</p>
            </div>
            <div className="motto__content">
                <h1 className="motto__title">Solusi Sehat, MOSHealth aja!</h1>
                <div className="motto__items">
                    <MottoItem 
                    image={touchIcon}
                    title="Mudah" 
                    text="Proses transaksi yang mudah, dan memiliki jaringan Apotek yang luas, tersebar di seluruh nusantara." 
                    smallIcon={true}/>

                    <MottoItem
                    image = {timerIcon}
                    title="Cepat"
                    text = "Pilihan pengiriman yang beragam. Mulai dari kurir apotek hingga ke expedisi online favoritmu."
                    minusLeft={true}
                    />
                    {/* design */}
                    <img src={greenGroup3} alt="" className="motto__plus3"/>

                    <MottoItem 
                    image = {trustIcon}
                    title = "Terpercaya"
                    text = "Produk terjamin kualitasnya dengan harga bersaing dan seluruh Apotek rekanan memiliki SIA / SIPA resmi."
                    />

                    
                </div>
            </div>
        </section>
    )
}

export default Motto;