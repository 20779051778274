import { gql } from "@apollo/client";

export const StoreRegistration=  gql`
mutation StoreRegistration(
  $name:String,
  $phone:String,
  $email:String,
  $storeName:String,
  $storeAddress:String
){
  StoreRegistration(
    name:$name,
    phone:$phone,
    email:$email,
    storeName:$storeName,
    storeAddress:$storeAddress
  ){
    response{
      status
      errMsg
    }
  }
}
`;

export const EmailSubscribe=gql`
mutation EmailSubscribe($email:String){
  EmailSubscribe(email:$email){
    response{
      status
      errMsg
    }
  }
}
`