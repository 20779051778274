import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { greenGroup3, mediumYellow, normalYellow, smallerYellow, smallGreen, smallYellow, yellowGroup2, yellowPlus } from '../../../assets';

const BisnisResponse = ({submitted})=>{
    useEffect(()=>{
        window.scrollTo({
            behavior:"smooth",
            top:0
        })
    },[submitted]);
    return(
        <section className="response">
            <div className="circle"></div>
            <div className="response__container">
                <div className="response__title">Terima Kasih</div>
                <div className="response__info">Team MOSHealth akan segera menghubungi anda</div>
                <NavLink to="/" className="response__btn">Beranda</NavLink>
                {/* CIRCLES */}
                <img src={mediumYellow} alt="" className="response__bigYellow"/>
                <img src={normalYellow} alt="" className="response__mediumYellow"/>
                <img src={smallYellow} alt="" className="response__smallYellow"/>
                <img src={smallerYellow} alt="" className="response__smallerYellow"/>
                <img src={smallGreen} alt="" className="response__smallGreen"/>
                {/* PLUS */}
                <img src={yellowPlus} alt="" className="response__plus1"/>
                <img src={yellowGroup2} alt="" className="response__plus2"/>
                <img src={greenGroup3} alt="" className="response__plus3"/>
            </div>
        </section>
    )
}

export default BisnisResponse;