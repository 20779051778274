import React from 'react';
import { apotek, apotekFavorit, bigPhone, dokter, items, mediumGreen2, normalGreen, playstore } from '../../../assets';
import FiturItem from '../../../components/fitur-item/FiturItem';

const Fitur = ()=>{
    return(
        <section className="fitur">
            <div className="fitur__content">
                <p className="fitur__title">Fitur MOSHealth</p>
                <div className="fitur__items">
                    <FiturItem 
                    image = {items}
                    title="Beli Obat & Vitamin"
                    text = "Penuhi kebutuhan kesehatan tubuh kamu sekarang"
                    />
                    <FiturItem 
                    image = {dokter}
                    addClass="fitur-item__resep"
                    title = "Tebus Resep"
                    text = "Kamu dapat mengunggah resep dan tebus lewat MOSHealth"
                    />
                    <FiturItem 
                    image = {apotek}
                    addClass="fitur-item__cari"
                    title="Cari Apotek"
                    text = "Telusuri apotek terpercaya di area sekitarmu"
                    />
                    <FiturItem 
                    image = {apotekFavorit}
                    title="Apotek Favorit"
                    text="Tandai apotek langgananmu"
                    />
                </div>
                <div className="fitur__text">
                    <span>#SolusiSehat</span> 
                    <a rel="noopener noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.moshealth.digitalbusiness.moshealth&hl=in" className="fitur__btn">Download</a>
                    <span>Moshealthaja</span>
                    <span>#Yuk100%Sehat</span>
                </div>
            </div>
            <div className="fitur__image">
                <img src={bigPhone} alt="moshealth" className="fitur__phone"/>
                <img src={playstore} alt="playstore" className="fitur__playstore"/>
            </div>

            {/* CIRCLES */}
            <img src={mediumGreen2} alt="green dot" className="fitur__bigDot"/>
            <img src={normalGreen} alt="green dot" className="fitur__smallDot"/>
        </section>
    )
}

export default Fitur;