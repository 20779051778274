import React from 'react';
import Motto from './motto/Motto';
import Fitur from './fitur/Fitur';
import { bigPhone1, greenGroup3, greenPlus, mediumYellow, normalGreen, smallerYellow } from '../../assets';

const Tentang = ()=>{
    return (
        <div id="tentang" >
            <section className="tentang" >
                <div className="tentang__content">
                    <h1 className="tentang__title">Tentang MOSHealth</h1>
                    <p className="tentang__paragraph">
                        <strong className="tentang__strong">MOSHealth</strong> adalah aplikasi layanan pembelian obat dan produk kesehatan di Apotek tepercaya
                    </p>
                    <p className="tentang__paragraph">
                        <strong className="tentang__strong">MOSHealth</strong> hadir bersama dengan jaringan apotek terpercaya
                        yang tersebar di seluruh nusantara
                        Aceh sampai dengan Papua.
                    </p>
                    <div className="tentang__info">
                        <p className="tentang__info-top"><strong className="tentang__number">+300</strong> Pharmacy Partners</p>
                        <p className="tentang__info-buttom">Increasing day by day</p>
                    </div>
                </div>
                <div className="tentang__image">
                    <img src={bigPhone1} alt="mos health" className="tentang__phone"/>
                    <img src={mediumYellow} alt="yellow Dot" className="tentang__bigDot"/>
                </div>

                {/* CIRCLES */}
                <img src={normalGreen} alt="green dot" className="tentang__normalDot"/>
                <img src={smallerYellow} alt="yellow dot" className="tentang__smallDot"/>

                {/* PLUS */}
                <img src={greenPlus} alt="plus" className="tentang__plus1"/>
                <img src={greenGroup3} alt="plus" className="tentang__plus3"/>
            </section>

            <Motto />
            <Fitur />
        </div>
    )
}

export default Tentang;