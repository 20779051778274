import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { greenGroup3, mediumGreen, normalYellow, smallerYellow, smallGreen, yellowGroup2, yellowPlus } from '../../../assets';
import { StoreRegistration } from '../../../GraphQL/Mutations';

const BisnisForm = ({setSubmitted})=>{


    const [storeRegistration,{loading,error,data}] =  useMutation(StoreRegistration);

    const [formSubmitted,setFormSubmitted] = useState(false);

    const [form,setForm] = useState({
        nama:'',
        handphone:'',
        email:'',
        apotek:'',
        alamat:''
    });

    const onSubmit = async (e)=>{
        e.preventDefault();
        try{
            await storeRegistration({
                variables:{
                    name:form.nama,
                    phone:form.handphone,
                    email:form.email,
                    storeName:form.apotek,
                    storeAddress:form.alamat
                }
            });
            setFormSubmitted(true);
        }catch(err){
            alert('Maaf, silahkan cek koneksi anda dan coba lagi');
        }
    }

    //RESPONSE REGISTRATION SUBMITTED
   useEffect(()=>{
    if(formSubmitted && data){
        if(data.StoreRegistration.response.status==="0"){
            setSubmitted(true);
        }else{
            alert('Maaf, terjadi kesalahan pada server. Silahkan coba kembali');
        }
        setFormSubmitted(false);
    }
   },[data,formSubmitted,setSubmitted])

    if(formSubmitted && error){
        alert('Maaf, terjadi kesalahan pada server. Silahkan coba kembali');
        setFormSubmitted(false);
    }

    return (
        <section className="form">
            <div className="circle"></div>
            <div className="form__header">Bergabung Bersama MOSHealth</div>
            <div className="form__info">
                Mari bergabung menjadi Apotek Partner MOSHealth 
                dan kembangkan bisnis Apotek anda bersama kami
            </div>
            <form className="form__container" onSubmit={onSubmit}>
                <div className="form__title">Form Registrasi Apotek Partner</div>
                <input type="text" className="form__input" placeholder="Nama" required
                value={form.nama} onChange={e=>setForm({...form,nama:e.target.value})} />
                <input type="text" className="form__input" placeholder="No Handphone" required
                value={form.handphone} onChange={e=>setForm({...form,handphone:e.target.value})}/>
                <input type="email" className="form__input" placeholder="Email" required
                value={form.email} onChange={e=>setForm({...form,email:e.target.value})}/>
                <input type="text" className="form__input" placeholder="Nama Apotek" required
                value={form.apotek} onChange={e=>setForm({...form,apotek:e.target.value})}/>
                <textarea className="form__area" placeholder="Alamat Apotek" required
                 value={form.alamat} onChange={e=>setForm({...form,alamat:e.target.value})}></textarea>
                <div className="form__btns">
                    <button className="form__btn"type="submit">Daftar Sekarang</button>
                {loading?<div className="loader"></div>:null}
                </div>
            </form>
            {/* CIRCLES */}
            <img src={mediumGreen} alt="" className="form__bigDot"/>
            <img src={normalYellow} alt="" className="form__mediumDot"/>
            <img src={smallGreen} alt="" className="form__normalDot"/>
            <img src={smallerYellow} alt="" className="form__smallDot"/>
            {/* PLUS */}
            <img src={yellowPlus} alt="" className="form__plus1"/>
            <img src={yellowGroup2} alt="" className="form__plus2"/>
            <img src={greenGroup3} alt="" className="form__plus3"/>
        </section>
    )
}

export default BisnisForm;