import React from 'react';
import {Route, Switch,HashRouter} from 'react-router-dom';
import Navigation from './components/navigation/Navigation';
import Home from './pages/Home';
import SyaratKetentuan from './pages/SyaratKetentuan';
import FAQ_PAGE from './pages/FAQ_PAGE';
import BisnisRegister from './pages/BisnisRegister';
import Spinner from './components/Spinner/Spinner';
import { useState } from 'react';
import { useEffect } from 'react';
import { apotek, apotek2, apotekFavorit, biggerGreen, biggerYellow, bigPhone, bigPhone1, bigPhone2, bigYellow, contactBackground, dokter, greenFacebook, greenGroup3, greenGroup3Big, greenInstagram, greenLabel, greenPlus, greenWhatsapp, items, mailIcon, mediumGreen, mediumGreen2, mediumYellow, mediumYellow2, mediumYellow3, MOSHealthLogo, MOSHealthLogoSmall, normalGreen, normalGreen2, normalYellow, pembayaranPoster, pembayaranVid, pemesananPoster, phoneIcon, playstore, QRISvid, registrasiPoster, registrasiVid, smallerYellow, smallGreen, smallYellow, smallYellow2, timerIcon, touchIcon, trustIcon, whiteFacebook, whiteInstagram, yellowGroup2, yellowGroup2_1, yellowGroup3, yellowGroup3Big, yellowPlus } from './assets';



const App = ()=>{
    const [isLoading,setIsLoading] = useState(true);

    const cacheImages = async (srcArray)=>{
        const promises = await srcArray.map(src=>{
            return new Promise ((resolve,reject)=>{
                const img = new Image();
                img.src = src;
                img.onload = resolve();
                img.onerror = reject();
            })
        });

        await Promise.all(promises);
        setIsLoading(false);
    }

    useEffect(()=>{
        const assets = [
            MOSHealthLogo,
            MOSHealthLogoSmall,
            bigPhone,
            bigPhone1,
            bigPhone2,
            apotek,
            apotek2,
            apotekFavorit,
            items,
            dokter,
            playstore,
            smallerYellow,
            smallYellow,
            smallYellow2,
            bigYellow,
            biggerYellow,
            mediumYellow,
            mediumYellow2,
            mediumYellow3,
            normalYellow,
            smallGreen,
            normalGreen,
            normalGreen2,
            mediumGreen,
            mediumGreen2,
            biggerGreen,
            greenFacebook,
            whiteFacebook,
            greenPlus,
            greenGroup3,
            greenGroup3Big,
            yellowPlus,
            yellowGroup3,
            yellowGroup3Big,
            yellowGroup2,
            yellowGroup2_1,
            greenWhatsapp,
            touchIcon,
            timerIcon,
            greenLabel,
            contactBackground,
            greenInstagram,
            phoneIcon,
            whiteInstagram,
            mailIcon,
            trustIcon,
            registrasiPoster,
            pemesananPoster,
            pembayaranPoster
        ];

        cacheImages(assets);
    },[])
    return(
        <>
        {
            isLoading ? <Spinner/> :
            <HashRouter>
                <div>
                    <Navigation />
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/FAQ" exact component={FAQ_PAGE}/>
                        <Route path="/terms-condition" exact component={SyaratKetentuan}/>
                        <Route path="/bisnis-register" exact component={BisnisRegister}/>
                        <Route path="/terms" exact component={SyaratKetentuan} />
                    </Switch>
                </div>
            </HashRouter> 
        }
        
        </>
    )
}

export default App;