//LOGO
export const MOSHealthLogo = require('./assets/Group 57.png');
export const MOSHealthLogoSmall = require('./assets/Group 146.png');

//BIG PHONE
export const bigPhone = require('./assets/56 ex.png');
export const bigPhone1 = require('./assets/asset-1.png');
export const bigPhone2 = require('./assets/asset-2.png');

//Apotek
export const apotek = require('./assets/aAsset 2mdpi.png');
export const apotek2 = require('./assets/Group 174.png');
export const apotekFavorit = require('./assets/Asset 1mdpi.png');

//Items
export const items = require('./assets/Asset -1.png');

//Dokter
export const dokter = require('./assets/Asset 2mdpi.png');

//playstore
export const playstore = require('./assets/download-app-store-png-9.png');

//yellow circle
export const smallerYellow = require('./assets/Ellipse 14.png');
export const smallYellow = require('./assets/Ellipse -1.png');
export const smallYellow2 = require('./assets/Ellipse 12.png');
export const bigYellow = require('./assets/Ellipse -3.png');
export const biggerYellow = require('./assets/Ellipse 9.png');
export const mediumYellow = require('./assets/Ellipse 10.png');
export const mediumYellow2 = require('./assets/Ellipse 13.png');
export const mediumYellow3 = require('./assets/Ellipse 36.png');
export const normalYellow = require('./assets/Ellipse 11.png');


//green circle
export const smallGreen = require('./assets/Ellipse 31.png');
export const normalGreen = require('./assets/Ellipse 30.png');
export const normalGreen2 = require('./assets/Ellipse 37.png');
export const mediumGreen =  require('./assets/Ellipse -2.png');
export const mediumGreen2 = require('./assets/Ellipse 15.png');
export const biggerGreen = require('./assets/Ellipse 25.png');

//facebook
export const greenFacebook = require('./assets/facebook (-1.png');
export const whiteFacebook = require('./assets/facebook (1).png');

//plus green
export const greenPlus = require('./assets/Group 177.png');
export const greenGroup3 = require('./assets/Group -1.png');
export const greenGroup3Big = require('./assets/Group 178.png');

//plus yellow
export const yellowPlus = require('./assets/Group 53.png');
export const yellowGroup3 = require('./assets/Group 56.png');
export const yellowGroup3Big = require('./assets/Group 176.png');
export const yellowGroup2 = require('./assets/Group 98.png');
export const yellowGroup2_1 = require('./assets/Group 175.png');

//Whatsapp
export const greenWhatsapp = require('./assets/Group 88.png');

//touch
export const touchIcon = require('./assets/Group 113.png');

//timer
export const timerIcon = require('./assets/Group 114.png');

//green label
export const greenLabel = require('./assets/Group 168.png');

//contact background
export const contactBackground = require('./assets/contact-background.png');

//instagram
export const greenInstagram = require('./assets/instagram-sketched (-1.png');
export const whiteInstagram = require('./assets/instagram-sketched (1).png');

//phone
export const phoneIcon = require('./assets/Layer -1.png');

//mail
export const mailIcon = require('./assets/Layer 4.png');

//trust
export const trustIcon = require('./assets/trust.png');

//video
export const registrasiVid = require('./assets/video/Registrasi MOSHealth.mp4');
export const pembayaranVid = require('./assets/video/Cara Transaksi MOSHealth.mp4');
export const QRISvid= require('./assets/video/Cara Bayar Pakai Qris Moshealth.mp4');

//poster
export const registrasiPoster = require('./assets/video/registrasiPoster.PNG');
export const pemesananPoster = require('./assets/video/pemesananPoster.PNG');
export const pembayaranPoster = require('./assets/video/pembayaranPoster.PNG');
