import React, {  useState } from 'react';
import { QRISvid, pembayaranVid, registrasiVid, registrasiPoster, pemesananPoster, pembayaranPoster } from '../../assets';

const menu = 'bagaimana__menu-item';
const menuActive = 'bagaimana__menu-item bagaimana--menu-active';

const dot = 'bagaimana__dot';
const dotActive = 'bagaimana__dot bagaimana--active';

const ytVid = 'https://www.youtube.com/embed/LWnxFknSDNc';

const active = {
    status:true,
    menuClass:menuActive,
    dotClass:dotActive
}

const inactive = {
    status:false,
    menuClass:menu,
    dotClass:dot
}


const Bagaimana  = ()=>{
    //status
    const [activeStatus,setActiveStatus] = useState({
        pengenalan:active,
        install:inactive,
        pemesanan:inactive,
        pembayaran:inactive,
        content:ytVid,
        poster:null
    });

    

    //change active state
    const onChangeActiveState = (name)=>{
        switch (name){
            case 'pengenalan':
                setActiveStatus({
                    pengenalan:active,
                    install:inactive,
                    pemesanan:inactive,
                    pembayaran:inactive,
                    content:ytVid,
                    poster:null
                });
                break;
            case 'install':
                setActiveStatus({
                    pengenalan:inactive,
                    install:active,
                    pemesanan:inactive,
                    pembayaran:inactive,
                    content:registrasiVid,
                    poster:registrasiPoster
                })
                break;
            case 'pemesanan':
                setActiveStatus({
                    pengenalan:inactive,
                    install:inactive,
                    pemesanan:active,
                    pembayaran:inactive,
                    content:pembayaranVid,
                    poster:pemesananPoster
                })
                break;
            case 'pembayaran':
                setActiveStatus({
                    pengenalan:inactive,
                    install:inactive,
                    pemesanan:inactive,
                    pembayaran:active,
                    content:QRISvid,
                    poster:pembayaranPoster
                })
                break;
            default:
                setActiveStatus({
                    pengenalan:active,
                    install:inactive,
                    pemesanan:inactive,
                    pembayaran:inactive,
                    content:ytVid,
                    poster:null
                })
        }
    }

    //next function
    const onNextContent = ()=>{
        if(activeStatus.pengenalan.status){
            setActiveStatus({
                pengenalan:inactive,
                install:active,
                pemesanan:inactive,
                pembayaran:inactive,
                content:registrasiVid,
                poster:registrasiPoster
            })
        }else if(activeStatus.install.status){
            setActiveStatus({
                pengenalan:inactive,
                install:inactive,
                pemesanan:active,
                pembayaran:inactive,
                content:pembayaranVid,
                poster:pemesananPoster
            })
        }

        else if(activeStatus.pemesanan.status){
            setActiveStatus({
                pengenalan:inactive,
                install:inactive,
                pemesanan:inactive,
                pembayaran:active,
                content:QRISvid,
                poster:pembayaranPoster
            })
        }

        else if(activeStatus.pembayaran.status){
            setActiveStatus({
                pengenalan:active,
                install:inactive,
                pemesanan:inactive,
                pembayaran:inactive,
                content:ytVid,
                poster:null
            })
        }
    }

    //prev function
    const onPrevContent = ()=>{
        if(activeStatus.pengenalan.status){
            setActiveStatus({
                pengenalan:inactive,
                install:inactive,
                pemesanan:inactive,
                pembayaran:active,
                content:QRISvid,
                poster:pembayaranPoster
            })  
        }else if(activeStatus.install.status){
            setActiveStatus({
                pengenalan:active,
                install:inactive,
                pemesanan:inactive,
                pembayaran:inactive,
                content:ytVid,
                poster:null
            })
        }

        else if(activeStatus.pemesanan.status){
            setActiveStatus({
                pengenalan:inactive,
                install:active,
                pemesanan:inactive,
                pembayaran:inactive,
                content:registrasiVid,
                poster:registrasiPoster
            })
            
        }

        else if(activeStatus.pembayaran.status){
            setActiveStatus({
                pengenalan:inactive,
                install:inactive,
                pemesanan:active,
                pembayaran:inactive,
                content:pembayaranVid,
                poster:pemesananPoster
            })
        }
    }

    return(
        <section className="bagaimana" id="bagaimana">
            <h1 className="bagaimana__title">How to?</h1>
            <div className="bagaimana__container">
                <div className="bagaimana__menu">
                    <div 
                    className={activeStatus.pengenalan.menuClass}
                    onClick={()=>onChangeActiveState('pengenalan')}>
                        Pengenalan
                    </div>
                    <div 
                    className={activeStatus.install.menuClass}
                    onClick={()=>onChangeActiveState('install')}>
                        Install
                    </div>

                    <div 
                    className={activeStatus.pemesanan.menuClass}
                    onClick={()=>onChangeActiveState('pemesanan')}>
                        Pemesanan
                    </div>

                    <div 
                    className={activeStatus.pembayaran.menuClass}
                    onClick={()=>onChangeActiveState('pembayaran')}>
                        Pembayaran
                    </div>
                </div>
                <div className="bagaimana__content">
                    <i 
                    className="fa fa-angle-right bagaimana__next" 
                    aria-hidden="true"
                    onClick={()=>onNextContent()}></i>

                    <i 
                    className="fa fa-angle-left bagaimana__prev" 
                    aria-hidden="true"
                    onClick={()=>onPrevContent()}></i>

                    {activeStatus.poster ?  <video  
                    src={activeStatus.content} 
                    className="bagaimana__video" 
                    poster={activeStatus.poster}
                    controls>
                    </video> : 
                    <iframe className="bagaimana__video" src={ytVid} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="MOSHEALTH promotion video"></iframe>
                    }
                </div>
            </div>
            <div className="bagaimana__dots">
                <div 
                className={activeStatus.pengenalan.dotClass}
                onClick={()=>onChangeActiveState('pengenalan')}></div>
                <div 
                className={activeStatus.install.dotClass}
                onClick={()=>onChangeActiveState('install')}></div>

                <div 
                className={activeStatus.pemesanan.dotClass}
                onClick={()=>onChangeActiveState('pemesanan')}></div>

                <div 
                className={activeStatus.pembayaran.dotClass}
                onClick={()=>onChangeActiveState('pembayaran')}></div>
            </div>
        </section>
    )
}

export default Bagaimana;