import React from 'react';
import { greenGroup3, mediumGreen, smallerYellow, smallYellow, yellowGroup2, yellowPlus } from '../../assets';
import Footer from '../../components/footer/Footer';

const Terms = ()=>{
    return(
        <>
        <section className="terms">
            {/* extra */}
            <img src={mediumGreen} alt="" className="terms__bigdot"/>
            <img src={smallYellow} alt="" className="terms__mediumdot"/>
            <img src={smallerYellow} alt="" className="terms__smalldot"/>
            <img src={smallerYellow} alt="" className="terms__smallerdot"/>
            <img src={yellowPlus} alt="" className="terms__plus1"/>
            <img src={yellowGroup2} alt="" className="terms__plus2"/>
            <img src={greenGroup3} alt="" className="terms__plus3"/>
            <div className="terms__title">Syarat dan Ketentuan Pengguna MOSHealth</div>
            <div className="terms__strong">Syarat dan Ketentuan</div>
            <p className="terms__text">
                Anda sebagai pengguna (“User”) aplikasi MOSHEALTH (“Aplikasi”) atau website MOSHEALTH (“Website”) diwajibkan untuk
                membaca Syarat dan Ketentuan Layanan Serta Penggunaan ini (“Ketentuan Penggunaan”) dan Kebijakan Privasi yang
                terlampir dengan hati-hati dan seksama sebelum mengunduh mau pun menggunakan setiap fitur dan/atau layanan yang
                tersedia dalam Aplikasi atau Website.
            </p>
            <div className="terms__line"></div>
            <p className="terms__text">
                Dengan mengunduh, memasang, dan/atau menggunakan Aplikasi atau Digital Platform atau Website, User setuju bahwa User
                telah membaca, memahami, mengetahui, menerima, terikat dan menyetujui seluruh informasi, syarat-syarat, dan ketentuan-
                ketentuan penggunaan Aplikasi atau Digital Platform atau Website yang terdapat dalam Ketentuan Penggunaan ini.
                Ketentuan Penggunaan ini merupakan suatu perjanjian yang sah mengikat terkait tata cara dan persyaratan penggunaan
                Aplikasi atau Digital Platform atau Website antara User dengan pengelola Aplikasi atau Digital Platform atau Website yaitu PT
                Enseval Putera Megatrading Tbk (“Kami”).
            </p>
            <p className="terms__text">Mohon diperhatikan bahwa Ketentuan Penggunaan dan Kebijakan Privasi dapat diperbarui dari waktu ke waktu dan pembaruan tersebut akan Kami beritahukan kepada User pada saat User mengakses Aplikasi atau Digital Platform atau Website. Silakan menghapus Aplikasi atau Digital Platform dalam perangkat elektronik User, termasuk mengajukan
            permintaan penghapusan akun (dalam hal User telah melakukan pendaftaran sebagai pengguna Aplikasi atau Digital
            Platform) dan tidak mengakses Digital Platform atau Website apabila User tidak setuju terhadap Ketentuan Penggunaan ini
            (sebagaimana diperbarui dari waktu ke waktu).</p>
            <p className="terms__text">
                A. KETENTUAN UMUM <br/>
                1. Koneksi internet diperlukan untuk dapat menggunakan Layanan. Segala biaya yang timbul atas koneksi perangkat
                elektronik User dengan jaringan internet merupakan tanggung jawab User sepenuhnya.<br/>
                2.Aplikasi ini merupakan aplikasi perangkat lunak yang berfungsi sebagai sarana untuk menghubungkan User dengan pihak
                ketiga yang menyediakan jasa atau menjual barang kepada User seperti (tetapi tidak terbatas pada) yaitu apotek dan/atau
                kurir (“Partner”).<br/>
                3. Jenis layanan yang dapat digunakan melalui Aplikasi adalah pemesanan produk kesehatan di apotik yang menjadi Partner
                Kami dan Layanan lain yang dapat kami tambahkan dari waktu ke waktu (“Layanan”).<br/>
                4. Digital Platform merupakan fitur yang berfungsi sebagai sarana Pengguna untuk mengakses beberapa Layanan Kami
                dengan mengunduh Aplikasi. Untuk saat ini, Digital Platform hanya dapat digunakan untuk layanan pemesanan produk
                kesehatan di apotik yang menjadi Partner.<br/>
                5. Untuk menghindari keragu-raguan, Kami bukan merupakan penyelenggara pelayanan kesehatan, apotek, tempat
                penyelenggaraan praktik medis, maupun kurir. Kami tidak mempekerjakan Partner dan Kami tidak bertanggung jawab atas
                setiap tindakan dan/atau kelalaian dari Partner. Aplikasi atau Digital Platform ini hanya merupakan sarana untuk memudahkan pencarian atas Layanan. <br/>
                6. Kami dapat menggunakan jasa pihak ketiga terkait penyediaan layanan pembayaran maupun layanan pengantaran
                pesanan. Karena itu segala bentuk kegagalan sistem terkait yang disebabkan hal yang di luar kendali Kami terkait dengan
                layanan pembayaran dan/atau layanan pengantaran pesanan yang disediakan pihak ketiga adalah di luar tanggung jawab
                Kami. Namun demikian, Kami akan berupaya melakukan upaya terbaik untuk membantu menyelesaikan masalah yang
                mungkin timbul. Penyedia jasa perbankan/pembayaran dan/atau jasa pengantaran pesanan yang dipilih oleh User dapat
                mengenakan biaya tambahan kepada User atas layanan yang diberikan.<br/>
                7. Kami tidak berada dalam posisi untuk memberikan saran kepada User dalam memilih Partner untuk melayani pesanan User
                yang dapat diakses melalui Aplikasi atau Digital Platform.<br/>
                8. Aplikasi ini memungkinkan User untuk mengirimkan permintaan atas suatu Layanan kepada Partner. Partner memiliki
                kebijakan sendiri dan kewenangan penuh untuk menerima atau menolak setiap permintaan User atas Layanan, hal mana
                adalah di luar kendali Kami dan karenanya bukan merupakan tanggung jawab Kami. Jika Partner menerima permintaan User,
                Aplikasi akan memberitahu User dan memberikan informasi mengenai Partner, termasuk nama Partner, nomor order dan
                kemampuan untuk menghubungi Partner melalui telepon.<br/>
                9. Kami berhak untuk sewaktu-waktu memperbarui, mengubah, menghapus, menambahkan, mengurangi dan/atau
                menerapkan syarat dan ketentuan baru dalam Ketentuan Penggunaan ini. Perubahan terhadap Ketentuan Penggunaan akan
                segera berlaku efektif dan akan diberitahukan kepada User pada saat User mengakses Aplikasi atau Digital Platform pertama
                kalinya setelah perubahan tersebut efektif. Pemakaian oleh User secara berkelanjutan terhadap Aplikasi atau Digital Platform akan dianggap sebagai persetujuan untuk tunduk kepada perubahan atas syarat dan ketentuan dari Ketentuan Penggunaan
                ini.<br/>
                10. Kami berhak sewaktu-waktu mengubah, menghapus, menambah, mengurangi dan/atau memperbarui fitur atau fasilitas
                dalam Aplikasi atau Digital Platform atau Website. Pemakaian User yang berkelanjutan terhadap Aplikasi atau Digital Platform atau Website akan dianggap sebagai persetujuan kepada perubahan, penghapusan, pengurangan, penambahan dan/atau
                pembaruan fitur atau fasilitas Aplikasi atau Digital Platform atau Website.<br/>
                11. User dapat memberikan informasi mengenai Data Pribadi, dan Kami akan menyimpan serta menampilkannya dalam akun
                User. Kerahasiaan data User terjamin dan akan digunakan oleh Kami dan/atau Partner untuk keperluan Kami sesuai dengan
                ketentuan perundang-undangan yang berlaku dan Kebijakan Privasi.<br/>
                12. Dengan menggunakan Aplikasi, User mengetahui bahwa percakapan melalui pembicaraan telepon maupun percakapan
                akan direkam dan User bersedia untuk direkam. Kerahasiaan percakapan User terjamin dan informasi tidak akan
                disebarluaskan.<br/>
                13. Kami berhak untuk tidak memproses registrasi, membatasi atau menghentikan penggunaan Aplikasi atau Digital Platform
                User jika kami memiliki alasan untuk mencurigai bahwa User telah melanggar ketentuan dari Ketentuan Penggunaan ini atau
                peraturan perundang-undangan yang berlaku.
            </p>

            <p className="terms__text">
                B. KETENTUAN PENGGUNAAN APLIKASI ATAU WEB APP ATAU WEBSITE<br/>
                1. Anda menyatakan dan menjamin bahwa Anda adalah individu yang memiliki hak untuk mengadakan perjanjian yang
                mengikat berdasarkan hukum Republik Indonesia dan bahwa anda telah berusia minimal 17 (tujuh belas) tahun atau sudah
                menikah dan tidak berada di bawah perwalian atau pengampuan. Kami berhak untuk menutup atau membatalkan akun Anda
                apabila hal tersebut tidak benar. Anda selanjutnya menyatakan dan menjamin bahwa Anda memiliki hak, wewenang dan
                kapasitas untuk menggunakan Layanan dan akan senantiasa mematuhi Ketentuan Penggunaan. Jika Anda mendaftarkan atas
                nama suatu badan usaha, Anda juga menyatakan bahwa Anda berwenang untuk bertindak untuk dan atas nama badan
                hukum tersebut dan untuk mengadakan dan mengikatkan badan hukum/entitas tersebut pada Ketentuan Penggunaan dan
                Aplikasi atau Web App<br/>
                2. User dapat menggunakan Aplikasi dengan terlebih dahulu melakukan pendaftaran yang disertai pemberian informasi data
                pribadi atau data badan hukum User sebagaimana diminta dalam Aplikasi (“Data User”). Informasi terkait Data User yang
                diberikan hanya akan digunakan oleh Kami dan/atau Partner untuk pemberian layanan-layanan dalam Aplikasi dan untuk
                tujuan lain yang telah User setujui sesuai dengan ketentuan perundang-undangan yang berlaku. Kebijakan Privasi yang
                terlampir (sebagaimana dimutakhirkan dari waktu ke waktu) menjadi bagian yang tidak terpisahkan dari Ketentuan
                Penggunaan ini. User harus memberikan informasi yang akurat dan lengkap, memperbarui informasi dan setuju untuk
                memberikan kepada kami bukti identitas apapun yang secara wajar dapat kami mintakan. Jika terdapat perubahan pada Data
                User yang User berikan kepada kami, misalnya, jika User mengubah alamat surel, nomor telepon, User wajib memperbarui
                rincian informasi User dengan mengirimkan permintaan User kepada kami.<br/>
                3. Setelah mendaftarkan diri pada Aplikasi, User akan mendapatkan suatu akun pribadi yang dapat diakses dengan kata
                sandi yang User pilih. User berjanji untuk tidak menyerahkan, mengalihkan maupun memberikan wewenang kepada orang
                lain untuk menggunakan identitas User atau menggunakan akun User. User wajib menjaga kerahasiaan kata sandi akun User
                dan setiap identifikasi yang kami berikan kepada User atas akun atau Data User. Dalam hal terjadi pengungkapan atas kata
                sandi User yang terjadi bukan atas kesalahan Kami, dengan cara apapun yang mengakibatkan penggunaan yang tidak sah
                dan tanpa kewenangan atas akun atau User, transaksi maupun pesanan atas Layanan yang dilakukan melalui Aplikasi masih
                akan dianggap sebagai transaksi maupun pesanan yang sah kecuali apabila User telah memberitahu Kami tentang hal
                tersebut sebelum Partner memberikan Layanan yang diminta.<br/>
                4. User diharuskan untuk melaporkan kepada Kami bila User, oleh sebab apapun, tidak memiliki kontrol atas akun User. User
                bertanggung jawab atas setiap penggunaan akun User dalam Aplikasi meskipun jika Akun tersebut telah disalahgunakan oleh
                orang lain.<br/>
                5. User tidak diperkenankan untuk membahayakan, menyalahgunakan, mengubah atau memodifikasi Aplikasi dengan cara
                apapun. Kami berhak untuk menutup atau membatalkan akun User dan melarang User untuk menggunakan Aplikasi lebih
                lanjut jika User menggunakan Aplikasi tanpa mematuhi Ketentuan Penggunaan ini.<br/>
                6. User memahami dan setuju bahwa penggunaan Aplikasi atau Web App oleh User akan tunduk pula pada Kebijakan Privasi
                Kami sebagaimana berlaku dari waktu ke waktu. Dengan menggunakan Aplikasi atau Web App, User juga memberikan
                persetujuan sebagaimana dipersyaratkan berdasarkan Kebijakan Privasi kami.<br/>
                7. User berjanji bahwa User akan menggunakan Aplikasi hanya untuk tujuan yang dimaksud untuk mendapatkan Layanan.
                User tidak diperbolehkan untuk menyalahgunakan atau menggunakan Aplikasi untuk aktivitas yang bertentangan dengan
                hukum, termasuk namun tidak terbatas kepada tindak pidana pencucian uang, pencurian, penggelapan, terorisme maupun
                penipuan. User juga berjanji bahwa User tidak akan menyebabkan ketidaknyamanan kepada orang lain atau melakukan
                pemesanan palsu melalui Aplikasi.<br/>
                8. Dengan memberikan informasi kepada kami, User menyatakan bahwa User berhak untuk memberikan kepada kami
                informasi yang akan digunakan oleh Kami dan Partner.<br/>
                9. User mengetahui dan setuju bahwa setiap informasi dalam bentuk apapun, termasuk namun tidak terbatas pada video,
                audio, gambar atau tulisan yang ada dalam Aplikasi atau Web App memiliki hak atas kekayaan intelektual (termasuk namun
                tidak terbatas kepada hak atas merek dan hak cipta) masing-masing. User tidak diperbolehkan untuk menggunakan,
                mengubah, memfasilitasi, menyebarluaskan dan/atau memutilasi hak atas kekayaan intelektual tersebut tanpa izin dari
                pemilik hak atas kekayaan intelektual tersebut sebagaimana diatur dalam peraturan perundang-undangan yang berlaku.<br/>
                10. Pada saat mengakses dan menggunakan Aplikasi atau Web App termasuk setiap fitur dan layanannya, User tidak
                diperkenankan untuk:<br/>
                a. mengalihkan akun User di Aplikasi kepada pihak lain tanpa persetujuan terlebih dahulu dari Kami.<br/>
                b. menyebarkan virus, spam atau teknologi sejenis lainnya yang dapat merusak dan/atau merugikan Aplikasi atau Web App
                dan pengguna lainnya.<br/>
                c. memasukkan atau memindahkan fitur pada Aplikasi atau Web App tanpa persetujuan dari Kami.<br/>
                d. menempatkan informasi atau aplikasi lain yang melanggar hak kekayaan intelektual pihak lain di dalam Aplikasi atau Web
                App.<br/>
                e. mengambil atau mengumpulkan Data User dari pengguna lain, termasuk tetapi tidak terbatas pada alamat surel, tanpa
                persetujuan dari pengguna tersebut.<br/>
                f. menggunakan Aplikasi atau Web App untuk untuk hal-hal yang dilarang berdasarkan hukum dan undang-undang yang
                berlaku. <br/>
                g. menggunakan Aplikasi atau Web App untuk mendistribusikan iklan atau materi lainnya.<br/>
                11. User mengetahui dan menyetujui bahwa tarif Layanan yang tercantum pada Aplikasi atau Web App dapat sewaktu-waktu
                mengalami perubahan.<br/>
                12. User memahami bahwa seluruh artikel mengenai kesehatan dan nutrisi yang Kami tampilkan hanya bersifat umum dan
                digunakan semata – mata untuk mengedukasi konsumen. Bagian mana pun dari artikel yang Kami tampilkan tidak dapat
                dianggap dan tidak dapat digunakan sebagai pengganti dari saran, diagnosis, atau perawatan medis. Walau berbagai upaya
                telah dilakukan untuk menampilkan data dan/atau informasi seakurat mungkin, Kami tidak bertanggung jawab atas segala
                kesalahan dan keterlambatan memperbarui data atau informasi, atau segala kerugian yang timbul karena tindakan yang
                berkaitan dengan penggunaan artikel, data/informasi dan waktu penyajian yang disajikan dalam Aplikasi atau Web App.<br/>
                13. User memahami bahwa seluruh informasi obat yang Kami tampilkan hanya bersifat umum dan bersifat untuk informasi
                semata. Kami tidak bertanggung jawab atas segala kesalahan dan keterlambatan memperbarui data atau informasi, atau
                segala kerugian yang timbul karena tindakan yang berkaitan dengan penggunaan artikel, data/informasi dan waktu penyajian
                yang disajikan dalam Aplikasi atau Web App<br/>
                14. Aplikasi atau Web App atau Website memiliki tautan – tautan ke situs – situs lain yang dioperasikan oleh pihak ketiga. Tautan-tautan ini tersedia untuk kemudahan User dan hanya digunakan untuk memberikan akses ke situs-situs pihak ketiga ini dan tidak untuk tujuan lain. Kami tidak menjamin kualitas, isi atau informasi mengenai produk atau layanan apa pun yang ditawarkan oleh situs – situs lain yang dioperasikan oleh pihak ketiga.
            </p>

            <p className="terms__text">
                C. LAYANAN<br/>
                1. Aplikasi atau Web ini memfasilitasi layanan pemesanan obat bebas (dot hijau), obat bebas terbatas (dot biru), obat keras (dot merah) dengan resep dokter, vitamin dan alat kesehatan bukan obat, makanan dan minuman sehat bukan obat
                (“Produk”) kepada User ke Apotik Partner.<br/>
                2. Aplikasi atau Web ini terkoneksi dengan pihak ketiga yaitu Partner yang menjual produk dan Partner yang mengantarkan
                pesanan.<br/>
                3. Fitur Aplikasi atau Web ini tidak dapat digunakan untuk pemesanan produk psikotropika, narkotika, dan obat pengguguran
                kandungan.
            </p>

            <p className="terms__text">
                D. TRANSAKSI USER<br/>
                1. Untuk dapat bertransaksi di Aplikasi, User dapat menggunakan berbagai metode pembayaran yang tersedia di Aplikasi.<br/>
                2. Apabila User mencurigai adanya aktivitas yang tidak wajar dan/atau terjadi perselisihan/sengketa sehubungan dengan
                akun User, User dapat segera menghubungi Kami agar Kami dapat segera mengambil tindakan yang diperlukan.<br/>
                3. User memahami dan menyetujui bahwa batas waktu pengajuan keluhan mengenai transaksi adalah maksimal 7 (tujuh) hari
                kalender setelah transaksi selesai.<br/>
                4. User memahami dan menyetujui bahwa keluhan yang disampaikan lebih dari 7 (tujuh) hari kalender setelah transaksi
                selesai bukan merupakan tanggung jawab Kami.
            </p>

            <p className="terms__text">
                E. KETENTUAN TRANSAKSI<br/>
                1. Pemesanan Produk<br/>
                a. User dapat melakukan pemesanan Produk untuk dibeli dan diantar melalui Aplikasi.<br/>
                b. Obat yang dapat dibeli oleh User adalah obat bebas (dot hijau), obat bebas terbatas (dot biru), obat keras (dot merah)
                dengan resep dokter, vitamin dan alat kesehatan bukan obat, makanan dan minuman sehat bukan obat.<br/>
                c. User dikenakan harga barang dan tarif antar sebagaimana yang tertera pada Aplikasi pada saat notifikasi “Checkout”.<br/>
                d. Waktu pengantaran dan ketersediaan obat bervariasi tergantung persediaan obat yang dimiliki Apotek rekanan Kami.<br/>
                e. Apabila pesanan telah diambil oleh apotek rekanan Kami dan/atau sedang dalam proses pengantaran oleh kurir pengantar
                rekanan Kami, maka User tidak dapat lagi membatalkan pesanan yang telah dilakukan.<br/>
                f. Nilai transaksi yang dapat User lakukan untuk satu kali transaksi adalah tidak kurang dari Rp. 10.000,- (sepuluh ribu
                Rupiah) dan tidak lebih dari Rp. 2.000.000,-(dua juta Rupiah).<br/>
                g. Harga yang dikenakan tersebut sudah termasuk Pajak Pertambahan Nilai (PPN).<br/>
                h. Kami tidak menjamin ketersediaan pesanan User karena hal ini tergantung pada update informasi ketersediaan stok oleh
                apotek rekanan Kami.<br/>
                i. Khusus untuk obat dengan resep dokter, User harus melampirkan dokumen berupa scan/foto resep dokter terlebih dahulu
                dan mengunggahnya melalui fitur Tebus Resep di dalam Aplikasi untuk verifikasi pembuatan order ke Apotek rekanan Kami.<br/>
                j. Resep dokter hanya dapat digunakan 1 (satu) kali saja dan User dilarang untuk mengunggah foto resep lebih dari 1 (satu) kali ataupun menebus ulang resep ke apotek lain apabila resep tersebut telah ditebus melalui Aplikasi. Kami tidak
                bertanggung jawab atas setiap kerugian dan risiko yang dapat timbul dari pemakaian obat yang dibeli melalui penebusan
                ulang resep. Kami mempunyai hak untuk membatalkan secara sepihak dalam hal pemesanan Produk menggunakan resep
                dokter yang tidak sesuai dengan peraturan dan ketentuan yang berlaku. <br/>
                k. Resep asli, yang digunakan sebagai dasar pembelian obat, wajib User serahkan kepada Kami untuk kami serahkan kepada
                Apotek rekanan.<br/>
                2. Penggunaan Kupon Promo<br/>
                a. Pada waktu tertentu, Kami dapat memberikan program promosi kepada User berupa kupon potongan untuk setiap
                pembelian dengan jumlah nominal tertentu<br/>
                b. Kami berhak, tanpa pemberitahuan sebelumnya, membatalkan penggunaan kupon oleh User jika ditemukan indikasi
                kecurangan atau pelanggaran yang dilakukan oleh User atas Ketentuan Penggunaan ini dan ketentuan hukum yang berlaku di
                wilayah negara Indonesia. Atas pembatalan tersebut, Kami tidak akan memberikan ganti rugi dalam bentuk apa pun.
            </p>

            <p className="terms__text">
                F. PEMBATASAN TANGGUNG JAWAB<br/>
                1. Kami tidak bertanggung jawab atas cidera, kematian, kerusakan atau kerugian langsung maupun tidak langsung, materiil
                maupun immateriil yang disebabkan oleh interaksi antara para Partner dengan User melalui Aplikasi. Kami juga tidak
                bertanggung jawab atas kesalahan atau tindakan apapun yang dilakukan oleh para Partner selama pelaksanaan Layanan.
                Partner hanya merupakan mitra kerja Kami, bukan pegawai, agen atau perwakilan Kami.<br/>
                2. Kami tidak bertanggung jawab atas setiap isi dan/atau pernyataan-pernyataan dalam percakapan yang dilakukan oleh User
                dengan Apotek Partner atau Apoteker menggunakan voice call dan chat pada Aplikasi, hal tersebut ialah percakapan dan
                interaksi pribadi antara User dengan Partner dan jelas di luar kendali kami, maka apabila di kemudian hari muncul
                permasalahan hukum mengenai isi dan/atau pernyataan-pernyataan dari percakapan dan interaksi antara User dengan
                Apotek Partner atau Apoteker dalam fitur-fitur tersebut, sepenuhnya menjadi tanggung jawab User dan User membebaskan
                Kami dari segala tuntutan, dakwaan dan akibat hukum yang ada.<br/>
                3. Dengan menggunakan Aplikasi, User setuju bahwa Kami tidak bertanggung jawab kepada User atau pun kepada pihak
                lainnya untuk segala kerugian yang disebabkan oleh tindakan yang diambil User atau pihak lain berdasarkan pemesanan
                obat, vitamin alat kesehatan melalui Aplikasi. Ketentuan tersebut di atas akan tetap berlaku meskipun User tidak lagi
                menggunakan Aplikasi.<br/>
                4. Dengan menggunakan Aplikasi, User setuju bahwa Kami tidak bertanggung jawab atas kandungan dan/atau efek obat dan/
                atau alat kesehatan yang dipesan.<br/>
                5. Dengan menggunakan Aplikasi, User menyetujui bahwa Kami tidak bertanggung jawab atas rusaknya atau hilangnya
                pesanan User saat pengiriman melalui kurir pengantar rekanan Kami dan Kami tidak bertanggung jawab terhadap kualitas
                Produk, tata cara pemberian Produk dan/atau kesalahan penerjemahan resep oleh apotek rekanan Kami.<br/>
                6. KAMI tidak bertanggung jawab atas perbuatan melawan hukum atau pelanggaran hukum yang dilakukan oleh User dan/
                atau Partner melalui dan/atau menggunakan APLIKASI dengan cara serta metode apapun tanpa terkecuali. User dan/atau
                Partner dengan ini memahami dan mengerti perbuatan melawan hukum atau pelanggaran hukum dimaksud termasuk tetapi
                tidak terbatas pada menjual atau mengedarkan produk palsu, menjual atau mengedarkan produk illegal, mengkonsumsi
                produk yang dilarang tegas oleh Undang-undang dan lainnya. Ketentuan ini akan tetap berlaku meskipun User dan/atau
                Partner tidak lagi menggunakan APLIKASI.
            </p>

            <p className="terms__text">
                G. HAK ATAS KEKAYAAN INTELEKTUAL <br/>
                1. PT Enseval Putera Megatrading Tbk adalah pemilik tunggal atas nama, ikon, dan logo “MOSHEALTH” serta pemegang hak
                kekayaan intelektual yang sah atas nama, ikon dan logo “MOSHEALTH” dalam Aplikasi tersebut, yang merupakan hak cipta
                dan merek yang dilindungi undang-undang. Dilarang keras menggunakan, mengubah, atau memasang nama, ikon, logo, atau
                merek tersebut tanpa persetujuan tertulis dari PT Enseval Putera Megatrading Tbk. <br/>
                2. Seluruh hak atas kekayaan intelektual yang terdapat dalam Aplikasi berdasarkan hukum negara Republik Indonesia,
                termasuk dalam hal ini adalah kepemilikan hak kekayaan intelektual atas seluruh source code Aplikasi dan hak kekayaan
                intelektual terkait Aplikasi. Untuk itu, User dilarang untuk melakukan pelanggaran atas hak kekayaan intelektual yang
                terdapat pada Aplikasi ini, termasuk melakukan modifikasi, karya turunan, mengadaptasi, menduplikasi, menyalin, menjual,
                membuat ulang, meretas, menjual, dan/atau mengeksploitasi Aplikasi ini termasuk penggunaan Aplikasi atas akses yang
                tidak sah, meluncurkan program otomatis atau script, atau segala program apapun yang mungkin menghambat operasi dan/
                atau kinerja Aplikasi, atau dengan cara apapun memperbanyak atau menghindari struktur navigasi atau presentasi dari
                Aplikasi atau isinya.<br/>
                3. User hanya diperbolehkan untuk menggunakan Aplikasi maupun salinan Aplikasi semata-mata untuk kebutuhan pribadi,
                non-komersial, non-eksklusif, tidak dapat dipindahtangankan, tidak dapat dialihkan dan tidak dapat disublisensikan.<br/>
                4. Kami memiliki hak sepenuhnya untuk melakukan tindakan hukum terhadap setiap pelanggaran yang dilakukan oleh User
                terkait dengan hak kekayaan intelektual yang terkait Aplikasi.
            </p>

            <p className="terms__text">
                H. PEMBERITAHUAN<br/>
                1. Semua pemberitahuan atau permintaan informasi kepada atau tentang Kami akan diproses jika dibuat secara tertulis dan
                dikirimkan ke alamat kami di: PT Enseval Putera Megatrading Tbk Jl Pulo Lentut no 10 Kawasan Industri Pulogadung Jakarta
                Timur atau alamat e-mail kami di : cs@moshealth.co.id atau call center kami di 021-50899981 <br/>
                2. Semua pemberitahuan dan/atau permintaan kepada atau tentang User akan diproses bila diserahkan langsung, dikirimkan
                melalui kurir, surat tercatat, atau alamat e-mail yang diberikan oleh User kepada Kami atau dengan cara memasang
                pemberitahuan atau permintaan tersebut di Aplikasi yang dapat diakses oleh umum tanpa dikenakan biaya. Pemberitahuan
                kepada User akan dianggap sudah diterima oleh User tersebut jika dan bila:<br/>
                a. Kami dapat menunjukkan bahwa pemberitahuan tersebut, baik dalam bentuk fisik maupun elektronik, telah dikirimkan
                kepada User tersebut; atau <br/>
                b. Kami sudah memasang pemberitahuan tersebut di Aplikasi yang dapat diakses oleh umum tanpa dikenakan biaya.
            </p>

            <p className="terms__text">
                I. KEAMANAN APLIKASI<br/>
                Kami senantiasa melakukan upaya yang wajar untuk menjaga Aplikasi atau Web App ini berfungsi dan berjalan lancar.
                Bagaimana pun juga, kami tidak bertanggung jawab dan tidak akan bertanggung jawab atas ketidaktersediaan Aplikasi atau
                Web App dan/atau fitur Layanan yang disebabkan oleh berbagai alasan, termasuk namun tidak terbatas pada keperluan
                pemeliharaan atau masalah teknis. Namun demikian, User mengetahui dan setuju bahwa transmisi atau akses internet tidak
                selamanya menciptakan suatu transaksi pembayaran yang aman dan pribadi, dan karenanya setiap pesan atau informasi
                yang User kirimkan atau gunakan dalam Aplikasi atau Web App mungkin dapat dibajak atau diambil oleh pihak ketiga yang
                tidak bertanggung jawab.
            </p>

            <p className="terms__text">
                J. TIDAK ADA JAMINAN<br/>
                Aplikasi atau Web App ini disediakan “sebagaimana adanya” dalam arti User mengakui dan menyetujui bahwa seluruh risiko
                yang ditimbulkan dari penggunaan fitur Aplikasi atau Web App oleh User tetap sepenuhnya ada pada User dan User tidak
                memiliki hak untuk meminta ganti rugi apapun dari Kami. Kami TIDAK memberikan pernyataan ataupun jaminan terkait
                dengan:<br/>
                1. bahwa Aplikasi atau Web App ini akan bebas dari kesalahan dan/atau kecacatan maupun saat beroperasi dengan
                kombinasi dengan perangkat keras, perangkat lunak, sistem atau data lainnya serta bahwa kesalahan dan/atau kecacatan
                dalam Aplikasi atau Web App akan diperbaiki.<br/>
                2. ketersediaan dan kehandalan Aplikasi atau Web App maupun server-server pendukung yang menyediakan Aplikasi atau
                Web App, termasuk terbebas dari virus dan komponen berbahaya lain.<br/>
                3. ketersediaan dan kehandalan Aplikasi atau Web App maupun server-server pendukung yang menyediakan Aplikasi atau
                Web App, termasuk terbebas dari virus dan komponen berbahaya lain.<br/>
                4. bahwa fitur dalam Aplikasi atau Web App akan sesuai dengan harapan dan kebutuhan User.<br/>
                5. bahwa kualitas produk, fitur dan informasi yang tersedia akan memenuhi harapan dan kebutuhan User.
            </p>

            <p className="terms__text">
                K. PENUTUP<br/>
                1. Hubungan Kami dengan User merupakan suatu hubungan independen dan diantara kita tidak ada hubungan keagenan,
                kemitraan, usaha patungan, karyawan-perusahaan atau pemilik waralaba-pewaralaba yang akan timbul dengan adanya
                Ketentuan Penggunaan ini.<br/>
                2. Judul di dalam Ketentuan Penggunaan ini dibuat hanya sebagai acuan, dan sama sekali tidak menetapkan, membatasi,
                menjelaskan atau menjabarkan apa yang termaktub dalam pasal tersebut.<br/>
                3. Kami memiliki hak untuk menginvestigasi dan menuntut hak Kami untuk setiap pelanggaran atas Ketentuan Penggunaan ini
                sepanjang yang dimungkinkan dan diperkenankan oleh hukum. User dengan ini mengakui bahwa Kami memiliki hak untuk
                memonitor akses penggunaan situs dan Aplikasi atau Web App untuk memastikan kepatuhan dengan Ketentuan Penggunaan
                ini, atau untuk mematuhi peraturan yang berlaku atau perintah atau persyaratan dari pengadilan, lembaga administratif atau badan pemerintahan lainnya.<br/>
                4. Pengguna tidak diperbolehkan untuk melakukan pencucian uang dan/atau menggunakan uang yang berasal dari sumber
                yang tidak sah secara hukum. Kami berhak untuk melaporkan transaksi yang dicurigai sebagai tindak pidana pencucian uang
                kepada pihak yang berwajib.<br/>
                5. Kami berhak, tanpa pemberitahuan sebelumnya, melakukan tindakan-tindakan yang diperlukan apabila ditemukan tindakan
                manipulasi/kecurangan, seperti menutup atau membatalkan akun, menarik subsidi, menarik cashback, membatalkan
                transaksi, menahan dana, serta hal-hal lainnya jika ditemukan indikasi kecurangan, pencucian uang atau pelanggaran yang
                dilakukan oleh User atas Ketentuan Penggunaan ini dan ketentuan hukum yang berlaku di wilayah negara Indonesia.<br/>
                6. Ketentuan Penggunaan ini diatur dan ditafsirkan serta dilaksanakan berdasarkan hukum yang berlaku di Negara Republik
                Indonesia dan User dengan tegas menyetujui bahwa bahwa ketentuan Pasal 1266 Kitab Undang-Undang Hukum Perdata dan
                ketentuan lainnya yang mewajibkan adanya pengesahan atau persetujuan pengadilan untuk dapat mengakhiri Ketentuan
                Penggunaan tidak berlaku terhadap Ketentuan Penggunaan ini.<br/>
                7. Segala sengketa yang berkaitan dengan Ketentuan Penggunaan ini, diselesaikan secara musyawarah untuk mufakat atau
                melalui Badan Arbitrase Nasional Indonesia (BANI), sesuai dengan prosedur yang berlaku di BANI. Apabila kedua belah pihak
                tidak sepakat untuk menyelesaikannya sengketa di BANI, maka sengketa akan diselesaikan melalui Pengadilan Negeri
                Jakarta Timur.
            </p>

            <p className="terms__text">
                KEBIJAKAN PRIVASI<br/>
                Kebijakan privasi ini (“Kebijakan Privasi”) akan menjelaskan bagaimana PT Enseval Putera Megatrading Tbk dan setiap anak
                perusahaan serta perusahaan afiliasinya (“Kami”), memperoleh, mengumpulkan, menggunakan, menampilkan,
                mengumumkan, mengungkapkan, memproses, membukakan akses, menyimpan, mengirim, memberi, mengalihkan,
                mengolah, mengelola, memusnahkan dan melindungi informasi dan Data User (secara bersama-sama, “Pemanfaatan”) yang
                User sebagai pengguna (“User”) aplikasi MOSHEALTH (“Aplikasi”) atau aplikasi web MOSHEALTH (“Web App”) berikan
                sebagaimana diminta maupun pada saat menggunakan fitur-fitur yang terdapat dalam Aplikasi (“Data User”). Perlu dicatat
                bahwa Data User di sini tidak termasuk Data User yang telah tersedia di domain publik.<br/>
                Kebijakan Privasi ini merupakan bagian dari Syarat dan Ketentuan Penggunaan (“Ketentuan Penggunaan”) Kami. Penggunaan
                Aplikasi atau Web App dan setiap fitur dan/atau layanan yang tersedia dalam Aplikasi atau Web App (“Layanan”) merupakan
                bentuk persetujuan User terhadap Ketentuan Penggunaan dan Kebijakan Privasi tersebut. Oleh karena itu, User perlu untuk
                membaca Kebijakan Privasi ini dengan seksama untuk memastikan bahwa User memahaminya sepenuhnya sebelum
                mendaftar, mengakses dan/atau menggunakan Aplikasi atau Web App dan Layanan Kami.<br/>
                1. Lingkup Kebijakan Privasi<br/>
                a. Kebijakan Privasi ini mengatur Pemanfaatan Data User.<br/>
                b. Dengan menggunakan Aplikasi, maka User dianggap telah membaca Kebijakan Privasi ini dan menyetujui mekanisme
                Pemanfaatan Data User sebagaimana diatur dalam Kebijakan Privasi ini. <br/>
                c. Apabila Kami meminta User untuk memberikan informasi ketika menggunakan Aplikasi atau Web App, maka informasi
                tersebut itu hanya akan digunakan untuk keperluan pemberian Layanan sesuai dengan Kebijakan Privasi ini.<br/>
                d. Kami berhak untuk sewaktu-waktu mengubah, menghapus dan untuk menerapkan ketentuan baru Kebijakan Privasi ini.
                User diharapkan untuk memeriksa halaman Kebijakan Privasi ini secara berkala untuk mengetahui perubahan tersebut.
                Dengan menggunakan layanan Aplikasi setelah terjadinya perubahan tersebut, User dianggap telah mengetahui dan
                menyetujui perubahan-perubahan ketentuan pada Kebijakan Privasi ini.<br/>
                2. Registrasi<br/>
                a. User diharuskan melakukan pendaftaran dalam Aplikasi untuk dapat menggunakan fitur Aplikasi.<br/>
                b. Untuk melakukan pendaftaran dalam Aplikasi, User harus memberikan informasi yang Kami perlukan sebagaimana
                tercantum pada Poin 3 (Data User) di bawah ini.<br/>
                3. Data Pribadi<br/>
                a. User mengetahui dan menyetujui bahwa Kami mengumpulkan informasi pribadi yang diberikan User saat User membuat
                akun dan profil maupun pada saat menggunakan fitur-fitur yang terdapat dalam Aplikasi atau Web App.<br/>
                b. Informasi mengenai identitas diri yang wajib diisi oleh User saat membuat akun di Aplikasi antara lain adalah:<br/>
                1. Nama lengkap sesuai kartu identitas yang berlaku (KTP atau Paspor)<br/>
                2. Tempat & tanggal lahir (KTP atau Paspor)<br/>
                3. Alamat surat elektronik / surel / e-mail.<br/>
                4. Nomor telepon genggam.<br/>
                5. Alamat lengkap sesuai kartu identitas yang berlaku (KTP atau Paspor)<br/>
                6. Jenis kelamin<br/>
                7. Tinggi badan dan berat badan<br/>
                c. User mengetahui dan menyetujui bahwa Kami juga berhak mengumpulkan informasi ketika User menggunakan Aplikasi,
                termasuk tetapi tidak terbatas pada: lokasi/koordinat GPS (jika User mengaktifkan fitur ini), alamat Internet Protocol (IP) (jika
                menggunakan browser), sistem operasi, jenis browser, alamat Aplikasi yang merujuk dan aktivitas User di Aplikasi. User
                dapat mengaktifkan atau menonaktifkan layanan pengenalan lokasi saat User menggunakan Aplikasi.<br/>
                d. User mengetahui dan menyetujui bahwa Kami berhak untuk melakukan verifikasi langsung kepada User atau pihak lainnya
                atas informasi mengenai data diri yang telah disampaikan User melalui Aplikasi.<br/>
                e. Dengan menggunakan Aplikasi, User menjamin bahwa informasi yang User berikan adalah akurat dan benar.<br/>
                f. Apabila informasi yang User berikan tersebut ternyata tidak benar, maka Kami tidak bertanggung jawab atas segala akibat
                yang dapat terjadi sehubungan dengan pemberian dan penggunaan informasi tidak benar tersebut.<br/>
                4. Data Pengunjung<br/>
                User mengetahui dan menyetujui bahwa Aplikasi akan mengumpulkan informasi tentang penggunaan, seperti (namun tidak
                terbatas pada) jumlah kunjungan, sumber pengunjung dan aktivitas pengunjung. Data ini kami kumpulkan dan kami gunakan
                dengan tujuan meningkatkan kepuasan User serta pelayanan Kami.<br/>
                5. Penggunaan Data Pribadi<br/>
                a. User dengan ini memberikan persetujuan secara tegas kepada Kami dan Partner untuk melakukan Pemanfaatan Data
                Pribadi User untuk kegiatan bisnis dan operasional maupun keperluan lain sesuai dengan tujuan diberikannya Data Pribadi
                tersebut, termasuk namun tidak terbatas untuk:<br/>
                1. Menyediakan informasi kepada User dan Partner sesuai permintaan User berkaitan dengan Layanan Kami.
            </p>

            <p className="terms__text">
                Terima Kasih <br/>
                MOSHealth
            </p>

            
        </section>
        <Footer />
        </>
    )
}

export default Terms;